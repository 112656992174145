import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";
import { Helmet } from 'react-helmet';
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import ContactForm from '../../components/contactForm/ContactForm';



function HomePage() {


  return (
    <div className="">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content={Logo} /> 
        <meta property="og:url" content="https://oxyera.com/contatti" />
        <meta property="og:type" content="page" /> 
        <meta name="keywords" content="" />
        <meta name="author" content="" />
        <link rel="canonical" href="https://oxyera.com/contatti" />
      </Helmet>
      <Navbar />

      {/* contact form */}
      <div id='contatti'>
        <ContactForm/>
      </div>

      <Footer/>
    </div>
  )
}

export default HomePage