import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import logoFarmacia from '../../../assets/logo/Untitled design (3).png';
import { useAuth } from '../../../context/AuthContext';
import oxyeraIcon from '../../../assets/icons/android-chrome-192x192.png';

const RecapView = ({ selectedDate, selectedOptionData, onClose, onConfirm }) => {
    const { cart } = useAuth(); // Ensure setCart is available

    return (
        <div className="w-full">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Riepilogo ordine</h2>
            <div className="bg-white p-4 rounded-lg">
                <div className="flex items-center mb-4">
                    <FaCalendarAlt className="text-blue-oxyera text-2xl mx-2" />
                    <span className="text-gray-700">{selectedDate} a partire dalle 10.00</span>
                </div>
                <div className="flex items-center mb-2 text-start">
                    <img
                        src={selectedOptionData?.image || logoFarmacia}
                        alt={selectedOptionData?.display_name || 'Farmacia'}
                        className="w-8 h-8 mr-3"
                    />
                    <div>
                        <p className="font-semibold text-gray-800">{selectedOptionData?.display_name || 'Nessuna farmacia selezionata'}</p>
                        <p className="text-gray-600">{selectedOptionData?.address || ''}</p>
                    </div>
                </div>
            </div>
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Dettagli Ordine</h3>
            <ul className="space-y-4 mb-6 overflow-y-auto max-h-[11rem] no-scrollbar">
                {cart.map((item, index) => (
                    <li key={index} className="flex items-center border-b border-gray-200 pb-2 mb-2">
                        <img
                            src={item.url || oxyeraIcon} // Add a default image if needed
                            alt={item.drug.split('*')[0]}
                            className="w-12 h-12 object-cover mr-3"
                        />
                        <div className="flex-1 text-start">
                            <p className="font-semibold text-gray-800">{item.drug.split('*')[0]}</p>
                            <p className="text-gray-600">Quantità: {item.quantity}</p>
                        </div>
                        <div className="flex-1 text-end">
                            <p className="text-gray-600">{item.public_price.toFixed(2)}€</p>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="flex justify-between items-center">
                <p className="font-semibold text-gray-800">Totale:</p>
                <p className="text-xl font-bold text-gray-800">{cart.reduce((total, item) => total + item.public_price * item.quantity, 0).toFixed(2)} €</p>
            </div>
            <div className="flex justify-center space-x-4 mt-6">
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-red-500 text-white rounded-full focus:outline-none w-36"
                >
                    Chiudi
                </button>
                <button
                    onClick={onConfirm}
                    className="px-4 py-2 bg-blue-oxyera text-white rounded-full focus:outline-none w-36"
                >
                    Invia
                </button>
            </div>
        </div>
    );
};

export default RecapView;
