import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import FullMinimalSection from '../../components/FullMinimalSection'
import FullBWSection from '../../components/FullBWsection';
import { ScrollParallax } from "react-just-parallax";
import img6 from '../../assets/media/about-us-2.png';
import img5 from '../../assets/media/about-us-1.png';
import PeopleSection from '../../components/People';


function AboutUs() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = (e) => {
        e.preventDefault(); // Prevent the default link behavior
    
        // Construct the mailto link
        const mailtoLink = 'mailto:info@oxyera.com?subject=Candidatura%20Spontanea';
    
        // Open the email client
        window.location.href = mailtoLink;
      };

    return (  // Add return here
        <div className="bg-white">
            <Helmet>
                <title>AboutUs - Oxyera</title>
                <meta name="description" content="Description of the AboutUs page" />
                <meta property="og:title" content="About Us - Oxyera" />
                <meta property="og:description" content="Description of the AboutUs page" />
                <meta property="og:image" content={Logo} /> 
                <meta property="og:url" content="https://oxyera.com/about-us/" />
                <meta property="og:type" content="website" /> 
                <meta name="keywords" content="oxyera, servizi, health" />
                <meta name="author" content="Oxyera" />
                <link rel="canonical" href="https://oxyera.com/about-us/" />
            </Helmet>
            <Navbar />

            {width > 768 ? <FullBWSection 
                background='bg-black'
                text='text-slate-50'
                buttonClass1='hidden'
                buttonClass2='hidden'
                darkButton={true}
                href2=''
                href='/'
                title='About us'
                description={`
                    <p class='font-bold mb-2'>Oxyera nasce da un team di giovani professionisti altamente qualificati, uniti dalla passione e da competenze complementari.</p>
                    <p class='pt-5' data-aos="fade-up" data-aos-duration="1000"> nostro obiettivo è semplificare l'accesso ai servizi farmaceutici, rendendoli più convenienti e accessibili per tutti. </p>
                    <p class='pt-5' data-aos="fade-up" data-aos-duration="1200"> Con un impegno costante verso l'innovazione e l'attenzione al cliente, investiamo in tecnologie all'avanguardia per offrire soluzioni affidabili e su misura per le esigenze di ogni individuo.</p>
                    `}
                image={img5}
                opacity='opacity-50'
                translate='lg:translate-x-[20%]'
                firstButtonText='Scopri di più'
            /> :
            <FullMinimalSection 
                headerImage={img5}
                logoTransp=''
                title='About us'
                description={`
                    <p class='text-sm font-bold mb-2'>Oxyera nasce da un team di giovani professionisti altamente qualificati, uniti dalla passione e da competenze complementari.</p>
                    <p class='text-sm pt-3' data-aos="fade-up" data-aos-duration="1000"> nostro obiettivo è semplificare l'accesso ai servizi farmaceutici, rendendoli più convenienti e accessibili per tutti. </p>
                    <p class='text-sm pt-3' data-aos="fade-up" data-aos-duration="1200"> Con un impegno costante verso l'innovazione e l'attenzione al cliente, investiamo in tecnologie all'avanguardia per offrire soluzioni affidabili e su misura per le esigenze di ogni individuo.</p>
                    `}
                logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
                buttonText='Scopri di più'
                darkButton={true}
                download={false}
                buttonClass='hidden'
                buttonClass2='hidden'
                href='/'
            />
            }

            <div className='relative z-20 h-lvh overflow-hidden'>
                <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.01">
                    <img src={img6} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
                </ScrollParallax>
                <div className="absolute top-0 left-0 lg:bg-black lg:bg-opacity-50 bg-blue-oxyera w-full h-full flex justify-center items-center">
                    {/* Text container centered and slightly moved to the right */}
                    <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                        <div className='lg:w-[50%]'>
                        <h2 className="lg:text-5xl text-4xl text-white font-semibold drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                        La nostra storia
                        </h2>
                        <p className="text-xl mt-4 mb-5 text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                        La nostra storia è caratterizzata da innovazioni, impegno e passione nel garantire un accesso equo ai servizi, considerando un diritto fondamentale per ogni individuo. 
                        </p>
                        <p className="text-xl mb-10 text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1200">
                        La soddisfazione dei nostri clienti è al centro della nostra attenzione e, con un approccio centrato sull'utente, investiamo costantemente sulle tecnologie per offrire soluzioni sempre più innovative e affidabili.
                        </p>
                        {/* <a href='/' >
                            <button className={`${width > 768 ? 'progress-button-light border-slate-50 text-slate-50' : 'border-black text-black progress-button-dark'}  px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full lg:w-1/2`}>
                            SCOPRI di più
                            </button>
                        </a> */}
                        </div>
                    </div>
                </div>
            </div>

            <PeopleSection/>

            <div className="bg-blue-oxyera py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    Join Oxyera
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-white">
                    Siamo sempre alla ricerca di persone talentuose e appassionate da inserire nel nostro team.
                    </p>
                    <div className="mt-10">
                    <button
                        onClick={handleClick}
                        className={`page-link progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full lg:w-1/2`}
                        >
                        Posizioni aperte
                        </button>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
}

export default AboutUs;
