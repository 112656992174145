import React, { useState } from "react";
import LoginPopUp from "./Login";
import RegisterPopUp from "./Registration";

function PopUp({ isOpen, onClose, onLoginStatus }) {
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);

    const handleSwitchToRegister = () => {
        setIsRegisterOpen(true);
    };

    const handleSwitchToLogin = () => {
        setIsRegisterOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div>
            {isRegisterOpen ? (
                <RegisterPopUp isOpen={isOpen} onClose={onClose} onSwitchToLogin={handleSwitchToLogin} />
            ) : (
                <LoginPopUp isOpen={isOpen} onClose={onClose} onSwitchToRegister={handleSwitchToRegister} onLoginStatus={onLoginStatus} />
            )}
        </div>
    );
}

export default PopUp;
