
  
  import { FaLinkedin } from 'react-icons/fa';
import AbuAbdelmahdi from '../assets/team/Abu Abdelmahdi (CEO & Founder).png';
  import AlessioCutrona from '../assets/team/Alessio Cutrona (CTO e Frontend Mobile App Developer).jpg';
  import EdoardoOldani from '../assets/team/Edoardo Oldani (Backend Developer).jpg';
  import GiovanniAndrisano from '../assets/team/Giovanni Andrisano (Partnership Manager).png';
  import HansAmico from '../assets/team/Hans Amico (CFO).png';
  import VirajPerera from '../assets/team/Viraj Perera (Frontend web Developer).jpg';
  

  function Staff() {

    return (
        <>
        <div className='mx-auto my-20 container'>
            <div className='flex justify-center items-center bg-white z-40'>
                <div className="mx-auto">
                    <div className="me-auto max-w-2xl text-start">
                        <h2 className="text-3xl font-bold tracking-tight text-blue-oxyera sm:text-4xl">Oxyera Team</h2>
                        {/* <p className="mt-6 text-lg leading-8 text-neutral-400">
                        We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the
                        best results for our clients.
                        </p> */}
                    </div>
                    <ul
                        role="list"
                        className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
                    >
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={AbuAbdelmahdi} alt="Abu Abdelmahdi"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Abu Abdelmahdi</h3>
                            <p className="text-sm leading-6 text-neutral-600">CEO & Founder</p>
                            <a href='https://www.linkedin.com/in/abu-abdelmahdi/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={HansAmico} alt="Hans Amico"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Hans Amico</h3>
                            <p className="text-sm leading-6 text-neutral-600">CFO</p>
                            <a href='https://www.linkedin.com/in/hans-amico-84834a200/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={AlessioCutrona} alt="Alessio Cutrona"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Alessio Cutrona</h3>
                            <p className="text-sm leading-6 text-neutral-600">CTO e Frontend Mobile App Developer</p>
                            <a href='https://www.linkedin.com/in/alessio-cutrona-553730152/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={GiovanniAndrisano} alt="Giovanni Andrisano"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Giovanni Andrisano</h3>
                            <p className="text-sm leading-6 text-neutral-600">Partnership Manager</p>
                            <a href='https://www.linkedin.com/in/giovanni-andrisano-046a05a8/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={VirajPerera} alt="Viraj Perera"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Viraj Perera</h3>
                            <p className="text-sm leading-6 text-neutral-600">Frontend web Developer</p>
                            <a href='https://www.linkedin.com/in/viraj-perera/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                        <li data-aos="fade-in" data-aos-duration="1500" >
                            <img className="mx-auto h-24 w-24 rounded-full border border-2 shadow-md border-blue-oxyera bg-contain" src={EdoardoOldani} alt="Edoardo Oldani"/>
                            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-blue-oxyera">Edoardo Oldani</h3>
                            <p className="text-sm leading-6 text-neutral-600">Backend Developer</p>
                            <a href='https://www.linkedin.com/in/edoardo-oldani-749635222/' target='_blank' className='text-blue-oxyera flex flex-row justify-center items-center text-sm'>
                                <FaLinkedin className='text-blue-oxyera me-2' />
                                Linkedin
                            </a>                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
  }
  
  export default Staff;