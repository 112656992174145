import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate  } from 'react-router-dom';
import './App.css';
// import WorkInProgress from './pages/WorkInProgress'
import HomePage from './pages/Home/HomePage'
import Servizi from './pages/Servizi/Servizi'
import Prodotti from './pages/Prodotti/Prodotti'
import Lottie from "lottie-react";
import loading from "./assets/lottie/loading.json";
import Contatti from './pages/Contatti/Contatti';
import AboutUs from './pages/aboutUs/AboutUs';
import Novita from './pages/Novita/Novita';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermOfCondition from './pages/TermOfCondition';
import ProdottiMobile from './pages/Prodotti/ProdottiMobile';


function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);  // This effect will run every time the location changes

  return null; // This component does not render anything
}


function App() {

  return (
    <div className="App">
      <div id="loading-overlay">
        <div id="lottie-container">
          <Lottie animationData={loading} loop={true} />
        </div>
      </div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/servizi" element={<Servizi />} />
          <Route path="/contatti" element={<Contatti />} />
          <Route path="/prodotti" element={<Prodotti />} />
          <Route path="/mobile/prodotti" element={<ProdottiMobile />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/novita" element={<Novita />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermOfCondition />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
