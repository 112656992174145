import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import oxyeraIcon from '../../../assets/icons/android-chrome-192x192.png';
import { FaTrashAlt } from 'react-icons/fa'; 

const CartComponent = ({nextStep}) => {
    const { cart, setCart } = useAuth(); // Assuming useAuth provides cart

    const handleIncreaseQuantity = (aic) => {
        setCart((prevCart) => {
          return prevCart.map(item =>
            item.AIC === aic
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        });
      };
    
      const handleDecreaseQuantity = (aic) => {
        setCart((prevCart) => {
          return prevCart
            .map(item =>
              item.AIC === aic
                ? { ...item, quantity: item.quantity - 1 }
                : item
            )
            .filter(item => item.quantity > 0);
        });
      };

      const handleRemoveFromCart = (aic) => {
        setCart((prevCart) => {
          return prevCart.filter(item => item.AIC !== aic);
        });
      };

    return (
        <>
            <div className="flex flex-row items-center justify-center mb-5">
                <h2 className="text-xl">Il tuo Ordine</h2>
            </div>
            <div className="flex-1 overflow-y-auto no-scrollbar max-h-[40vh] w-full">
                <ul className="flex-1 overflow-y-auto text-start no-scrollbar text-center">
                    {cart.length > 0 ? (
                        cart.map(item => (
                            <li key={item.AIC} className="relative flex items-center p-4 shadow-sm w-full h-full border-b last:border-none">
                                {/* Product Image */}
                                <img
                                    src={item.url || oxyeraIcon}
                                    alt={item.drug || "Product Image"}
                                    className="w-10 h-10 mr-4 object-cover"
                                />
                                {/* Product Details */}
                                <div className="flex-grow flex flex-col">
                                    <p className="text-sm font-semibold w-[10rem]">{item.drug?.split('*')[0] || "Unnamed Product"}</p>
                                    <p className="text-sm">{item.drug?.split('*')[1]}</p>
                                    <div className="flex items-center mt-1">
                                        <span className="me-3">Qtà: </span>
                                        {/* Quantity Controls */}
                                        <button
                                            onClick={() => handleDecreaseQuantity(item.AIC)}
                                            className="bg-[#04899e] bg-opacity-25 rounded-full w-[1.5rem] h-[1.5rem] me-2"
                                        >
                                            -
                                        </button>
                                        <span className="text-md font-medium">{item.quantity}</span>
                                        <button
                                            onClick={() => handleIncreaseQuantity(item.AIC)}
                                            className="bg-[#04899e] bg-opacity-25 rounded-full w-[1.5rem] h-[1.5rem] ms-2"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {/* Absolute Positioned Price and Trash Icon */}
                                <span className="text-sm absolute right-2 top-4">{(item.public_price || 0).toFixed(2)}€</span>
                                <FaTrashAlt
                                    className="absolute right-2 bottom-4 text-red-600 cursor-pointer hover:text-red-800 transition-colors"
                                    onClick={() => handleRemoveFromCart(item.AIC)}
                                />
                            </li>
                        ))
                    ) : (
                        <p className="text-center">Il carrello è vuoto</p>
                    )}
                </ul>
            </div>
            {/* Total Price Button */}
            <button
                className="flex justify-between items-center px-8 py-3 bg-orange-500 rounded-full mt-10 mb-5 text-white"
                onClick={nextStep}
            >
                <span className="font-semibold flex flex-row items-center justify-start">
                    Prosegui:
                </span>
                <span className="text-xl font-semibold ms-3">
                    {cart.reduce((total, item) => total + (item.public_price || 0) * item.quantity, 0).toFixed(2)}€
                </span>
            </button>
        </>
    );
};

export default CartComponent;
