import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import animationData from '../../assets/lottie/Check.json';
import animationAlert from '../../assets/lottie/alert.json';
import Lottie from 'react-lottie';


function RegisterPopUp({ isOpen, onClose, onSwitchToLogin }) {
    const [step, setStep] = useState(1);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(null)
    const [passwordError, setPasswordError] = useState('');
    const [otp, setOtp] = useState('');
    const [accessToken, setAccessToken] = useState('')
    const [sendOtp, setSendOtp] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };

    if (!isOpen) return null;

    const handleNextStep = () => {
        if (step === 1 && (name.trim() === '' || surname.trim() === '' || email.trim() === '')) {
            return;
        }
    
        if (step === 2 && (fiscalCode.trim() === "" || phone.trim() === "")) {
            return;
        }
    
        if (step === 3 && passwordError !== "") {
            return;
        }

        if(step === 3 && (password.trim() === "" || confirmPassword.trim() === "")){
            validatePasswords(password,confirmPassword);
            return;
        }

        if(step === 5 && (otp.trim() === "")){
            return;
        }
    
        if (step < 6) setStep(step + 1);
    };
    

    const handlePreviousStep = () => {
        if (step > 1) setStep(step - 1);
    };

    
    const handleSendOtp = async () => {
        // e.preventDefault();
        const newAccessToken =  sessionStorage.getItem('registration_token') || accessToken;

        try {
            const response = await axios.post('https://app.oxyera.com/auth/verify_email', 
                {},
                {
                    headers: {
                        Authorization: `${newAccessToken}` // Replace `yourAuthToken` with your actual token variable
                    }
                }
            );

            if(response.status === 200) {
                handleNextStep();
                return;
            }
            if(response.status === 401){
                alert('Invalid confirmation code');
                console.log(response);
                setSendOtp(false)
                return;   
            }

            if(response.status === 404){
                alert('User not found');
                console.log(response);
                setSendOtp(false)
                return;   
            }

        } catch (error) {
            console.error("Error during login:", error);
            setSendOtp(false)
            return;   
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Registration submitted with:", { name, surname, email, fiscalCode, phone, confirmPassword });

        try {
            const response = await axios.post('https://app.oxyera.com/auth/signup', 
                {
                    email: email,
                    firstname: name,
                    lastname: surname,
                    fiscal_code: fiscalCode,
                    phone: phone,
                    password_hash: confirmPassword
                }
            );
            if(response.status === 200) {
                sessionStorage.setItem('registration_token', "Bearer " + response.data);
                setAccessToken(response.data)
                handleSendOtp();
                return;
            }
            if(response.status === 409){
                alert('Email or fiscal code already exists');
                console.log(response);
                setRegistrationStatus(false);
                handleNextStep()
                return;   
            }

        } catch (error) {
            console.error("Error during login:", error);
            setRegistrationStatus(false);
            handleNextStep()
            return;   
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        const newAccessToken =  sessionStorage.getItem('registration_token') || accessToken;

        try {
            const response = await axios.post('https://app.oxyera.com/auth/verify_confirmation_code', 
                {
                    confirmation_code: otp
                },
                {
                    headers: {
                        Authorization: `${newAccessToken}` // Replace `yourAuthToken` with your actual token variable
                    }
                }
            );

            if(response.status === 200) {
                setRegistrationStatus(true);
                sessionStorage.removeItem('registration_token');
                handleNextStep();
                return;
            }
            if(response.status === 401){
                alert('Invalid confirmation code');
                console.log(response);
                setRegistrationStatus(false);
                handleNextStep()
                return;   
            }

            if(response.status === 404){
                alert('User not found');
                console.log(response);
                setRegistrationStatus(false);
                handleNextStep()
                return;   
            }

        } catch (error) {
            console.error("Error during login:", error);
            setRegistrationStatus(false);
            handleNextStep()
            return;   
        }
    };

    const defaultOptions = {
        loop: false,  // Disable looping
        autoplay: true, // Start playing as soon as it's rendered
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const defaultOptionsError = {
        loop: false,  // Disable looping
        autoplay: true, // Start playing as soon as it's rendered
        animationData: animationAlert,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const validatePasswords = (password, confirmPassword) => {
        if (password.trim() === "" || confirmPassword.trim() === "") {
            setPasswordError('Le password non possono essere vuote.');
            return false;
        } else if (password !== confirmPassword) {
            setPasswordError('Le password non corrispondono.');
            return false;
        } else if (password.length < 6 || confirmPassword.length < 6) {
            setPasswordError('La password deve contenere almeno 6 caratteri.');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };
    
    
    // Function to handle password input change
    const handlePasswordChange = (x) => {
        const newPassword = x;
        setPassword(newPassword);
        validatePasswords(newPassword, confirmPassword); // Validate as the user types
    };
    
    // Function to handle confirm password input change
    const handleConfirmPasswordChange = (y) => {
        const newConfirmPassword = y;
        setConfirmPassword(newConfirmPassword);
        validatePasswords(password, newConfirmPassword); // Validate as the user types
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 glassmorphism">
            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-sm">
                <div className="flex flex-row justify-end">
                    <button onClick={onClose}>
                        <IoClose className="text-2xl text-gray-600 hover:text-black transition-colors" />
                    </button>
                </div>
                <div className="flex flex-row justify-center items-center pb-5">
                    <img src={Logo} alt="Logo" className="h-10 w-auto" />
                    <p className="ml-2 text-2xl font-semibold text-blue-oxyera">Register</p>
                </div>
                <form>
                    {step === 1 && (
                        <div className="mb-4 text-start">
                            <label className="block text-sm font-medium text-gray-700">Nome</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                            <label className="block text-sm font-medium text-gray-700 mt-4">Cognome</label>
                            <input
                                type="text"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                            <label className="block text-sm font-medium text-gray-700 mt-4">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                            {(name.trim() === '' || surname.trim() === '' || email.trim() === '') && (
                                <p className="text-red-500 text-sm mt-1">* Devi compilare tutti i campi</p>
                            )}
                        </div>
                    )}

                    {step === 2 && (
                        <div className="mb-4 text-start">
                            <label className="block text-sm font-medium text-gray-700">Codice Fiscale</label>
                            <input
                                type="text"
                                value={fiscalCode.toUpperCase()}
                                onChange={(e) => setFiscalCode(e.target.value.toUpperCase())}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                            <label className="block text-sm font-medium text-gray-700 mt-4">Numero di telefono</label>
                            <input
                                type="text"
                                value={phone}
                                onChange={(e) => {
                                    const numericValue = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    setPhone(numericValue);
                                }}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                            {(fiscalCode.trim() === "" || phone.trim() === "") && (
                                <p className="text-red-500 text-sm mt-1">* Devi compilare tutti i campi</p>
                            )}
                        </div>
                    )}

                    {step === 3 && (
                        <div className="mb-4 text-start">
                            {/* Password Field */}
                            <div className="relative mb-4">
                                <label className="block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => handlePasswordChange(e.target.value)}
                                    required
                                    className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 ${
                                        passwordError ? 'border-red-500' : 'border-gray-300'
                                    }`}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-3 mt-5 flex items-center text-gray-500"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>

                            {/* Confirm Password Field */}
                            <div className="relative mb-4">
                                <label className="block text-sm font-medium text-gray-700">Conferma Password</label>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                    required
                                    className={`w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-500 ${
                                        passwordError ? 'border-red-500' : 'border-gray-300'
                                    }`}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-3 mt-5 flex items-center text-gray-500"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>

                            {/* Error Message */}
                            {passwordError && (
                                <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                            )}
                        </div>

                    )}

                    {step === 4 && (
                        <div className="mb-4 text-start">
                            <h3 className="text-lg text-center font-semibold mb-2">Ricontrolla i dati inseriti</h3>
                            <p><strong className="text-blue-oxyera">Nome:</strong> {name}</p>
                            <p><strong className="text-blue-oxyera">Cognome:</strong> {surname}</p>
                            <p><strong className="text-blue-oxyera">Telefono:</strong> + {phone}</p>
                            <p><strong className="text-blue-oxyera">Email:</strong> {email}</p>
                            <p><strong className="text-blue-oxyera">Codice Fiscale:</strong> {fiscalCode}</p>
                        </div>
                    )}

                    {step === 5 && (
                        <div className="mb-4 text-start">
                            {/* {sendOtp ? 
                            <> */}
                                <h3 className="text-md font-semibold mb-2">Inserisci il codice di verifica</h3>
                                <p className="text-sm text-gray-700 mb-4">
                                    Abbiamo inviato un codice di verifica al tuo indirizzo email, Verifica la casella postale.
                                </p>
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={(e) => {setOtp(e.target.value)}}
                                    required
                                    className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                                />
                                
                                {(otp.trim() === "" || otp.trim() === "") && (
                                    <p className="text-red-500 text-sm mt-1">* Questo campo non può essere vuoto</p>
                                )}
                            {/* </>
                            :
                            <>
                                <div className="flex flex-col items-center">
                                    <h3 className="text-md font-semibold mb-2">Invia OTP</h3>
                                    <p className="text-sm text-gray-700 mb-4">
                                        Riceverai un codice all'indirizzo email che hai inserito in fase di registrazione
                                    </p>
                                    <button
                                        type="button"
                                        onClick={handleSendOtp}
                                        className="px-4 py-2 border border-blue-oxyera text-blue-oxyera rounded hover:bg-[#04899e] hover:text-white transition-colors"
                                    >
                                        Invia OTP
                                    </button>
                                </div>
                            </>
                            } */}
                        </div>
                    )}


                    {step === 6 && registrationStatus &&(
                        <div className="mb-4 text-center"> {/* Changed to text-center for centering */}
                            <h3 className="text-lg font-semibold mb-2">Grazie per la registrazione</h3>
                            <div className="my-4">
                                <Lottie 
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </div>
                    )}
                    {step === 6 && !registrationStatus &&(
                        <div className="mb-4 text-center"> {/* Changed to text-center for centering */}
                            <h3 className="text-lg font-semibold mb-2">Ops, qualcosa è andato storto!</h3>
                            <div className="my-4">
                                <Lottie 
                                    options={defaultOptionsError}
                                    height={150}
                                    width={150}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex justify-center space-x-5 mt-6">
                        {step > 1 && step < 5 && (
                            <button
                                type="button"
                                onClick={handlePreviousStep}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors"
                            >
                                Indietro
                            </button>
                        )}
                        {step < 4 && (
                            <button
                                type="button"
                                onClick={handleNextStep}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                Avanti
                            </button>
                        )}
                        {step === 4 && (
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                Avanti
                            </button>
                        )}
                        {step === 5 && (
                            <>
                            <button
                                type="button"
                                onClick={handlePreviousStep}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors"
                            >
                                Indietro
                            </button>
                            <button
                                type="submit"
                                onClick={handleOtpSubmit}
                                className="px-8 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                            >
                                Invia
                            </button>
                            </>
                        )}
                        {step === 6 && registrationStatus && ( 
                            <button
                                type="button"
                                onClick={onSwitchToLogin}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors"
                            >
                                chiudi
                            </button> 
                        )}
                        {step === 6 && !registrationStatus && ( 
                            <button
                            type="button"
                            onClick={handlePreviousStep}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-gray-400 transition-colors"
                        >
                            Riprova
                        </button> 
                        )}

                    </div>
                </form>
                {step === 1 && 
                    <div className="mt-4 text-center">
                        <p className="text-sm text-gray-600">
                            Hai già un Account?{" "}
                            <button
                                onClick={onSwitchToLogin}
                                className="text-blue-500 hover:underline"
                            >
                                Accedi
                            </button>
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default RegisterPopUp;
