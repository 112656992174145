import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios'; // Import Axios
import logo from '../../assets/logo/OXYERA_Logo_nbg.png'; // Import your logo file

function Contact() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Define the function to handle form submission
  const onSubmit = async (data) => {
    const message = `Nome: ${data.name} ${data.surname} \n Telefono: ${data.phone} \n Email: ${data.email} \n Interessi: ${data.interests}`;

    // Set up the request body as per the required format
    const requestBody = {
      to: "info@oxyera.com",
      subject: "new contact from oxyera.com",
      text: message,
    };

    try {
      // Make a POST request to the API endpoint
      const response = await axios.post('https://app.oxyera.com/email/send', requestBody);

      // Handle successful response
      if (response.status === 201) {
        setSuccessMessage('Messaggio inviato con successo!');
      } else {
        setErrorMessage("Ops, si è verificato un errore durante l'invio!");
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage("Ops, si è verificato un errore durante l'invio!");
    }
  };

  return (
    <div className="h-dvh flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-blue-oxyera">
      <div className="flex justify-end items-center w-56">
        <img src={logo} alt="Logo" className="h-24 w-auto" />
        <p className="ml-3 text-4xl font-semibold text-white">Oxyera</p>
      </div>      
      <div className="max-w-md w-full">
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <p className="text-xl font-semibold text-white">Compila il form per informazioni</p>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px">
            <div className="flex space-x-4 mb-5">
              <div className="w-1/2">
                <label htmlFor="name" className="sr-only">Nome</label>
                <input {...register("name", { required: true })} id="name" name="name" type="text" autoComplete="name" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nome" />
                {errors.name && <span className="text-red-500">Il Nome è obbligatorio</span>}
              </div>
              <div className="w-1/2">
                <label htmlFor="surname" className="sr-only">Cognome</label>
                <input {...register("surname", { required: true })} id="surname" name="surname" type="text" autoComplete="surname" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Cognome" />
                {errors.surname && <span className="text-red-500">Il Cognome è obbligatorio</span>}
              </div>
            </div>
            <div className='w-full pb-5'>
              <label htmlFor="phone" className="sr-only">Numero di Telefono</label>
              <input {...register("phone", { required: true })} id="phone" name="phone" type="tel" autoComplete="tel" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Numero di Telefono" />
              {errors.phone && <span className="text-red-500">Il Numero di Telefono è obbligatorio</span>}
            </div>
            <div className='w-full pb-5'>
              <label htmlFor="email" className="sr-only">Indirizzo Email</label>
              <input {...register("email", { required: true })} id="email" name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Indirizzo Email" />
              {errors.email && <span className="text-red-500">L'indirizzo Email è obbligatorio</span>}
            </div>
            <div className='w-full pb-5'>
              <label htmlFor="interests" className="sr-only">Interessi</label>
              <textarea {...register("interests", { required: false })} id="interests" name="interests" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Interessi o Messaggio" />
            </div>
          </div>
          {successMessage && <p className="text-green-500">{successMessage}</p>}
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <div>
            <button className={`page-link progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}>
                  Invia
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
