import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { hydrate } from "react-dom";
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './context/AuthContext';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-T3QWPNLF'
}

TagManager.initialize(tagManagerArgs);


AOS.init();


const rootElement = document.getElementById("root");
const root = createRoot(rootElement); 


if (rootElement.hasChildNodes()) {
  hydrate(  
  <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
  </React.StrictMode>, rootElement);
} else {
  root.render(  
  <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
  </React.StrictMode>);
}

reportWebVitals();
