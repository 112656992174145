import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import FullMinimalSection from '../../components/FullMinimalSection'
import FullBWSection from '../../components/FullBWsection';
import { ScrollParallax } from "react-just-parallax";
import img6 from '../../assets/media/6.webp';
import img5 from '../../assets/media/5.webp';
import img7 from '../../assets/media/7.webp';
import img8 from '../../assets/media/8.webp';
import img9 from '../../assets/media/chart.png';
import coomingSoon from '../../assets/icons/home4.png';




function Servizi() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  

    return (  // Add return here
        <>
            <Helmet>
                <title>Servizi - Oxyera</title>
                <meta name="description" content="Description of the servizi page" />
                <meta property="og:title" content="Servizi - Oxyera" />
                <meta property="og:description" content="Description of the servizi page" />
                <meta property="og:image" content={Logo} /> 
                <meta property="og:url" content="https://oxyera.com/servizi" />
                <meta property="og:type" content="website" /> 
                <meta name="keywords" content="oxyera, servizi, health" />
                <meta name="author" content="Oxyera" />
                <link rel="canonical" href="https://oxyera.com/servizi" />
            </Helmet>
            <Navbar />

            {width > 768 ? <FullBWSection 
                background='bg-black'
                text='text-slate-50'
                buttonClass1='hidden'
                buttonClass2='hidden'
                darkButton={true}
                href2=''
                href='/'
                title='SERVIZI'
                description={`
                    <p class='font-bold mb-2'>Farmaci a portata di mano, quando ne hai bisogno</p>
                    <p class=''>Semplifichiamo la tua routine quotidiana. 
                    Meno stress, più tranquillità: la tua salute a portata di click.</p>
                    `}
                image={img5}
                opacity='opacity-50'
                translate='lg:translate-x-[20%]'
                firstButtonText='Scopri di più'
            /> :
            <FullMinimalSection 
                headerImage={img5}
                logoTransp=''
                title='SERVIZI'
                description={`
                    <p class='text-2xl font-semibold mb-2'>Farmaci a portata di mano, quando ne hai bisogno</p>
                    <p class='text-lg'>Semplifichiamo la tua routine quotidiana. 
                    Meno stress, più tranquillità: la tua salute a portata di click.</p>
                    `}
                logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
                buttonText='Scopri di più'
                darkButton={true}
                download={false}
                buttonClass='hidden'
                buttonClass2='hidden'
                href='/'
            />
            }

            <div className='relative z-20 h-lvh overflow-hidden'>
                <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                    <img src={img6} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
                </ScrollParallax>
                <div className="absolute top-0 left-0 lg:bg-black lg:bg-opacity-50 bg-blue-oxyera w-full h-full flex justify-center items-center">
                    {/* Text container centered and slightly moved to the right */}
                    <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                        <div className='lg:w-[50%]'>
                        <h2 className="lg:text-5xl text-4xl text-white font-semibold drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                            Ritiro e Consegna
                        </h2>
                        <p className="text-lg sm:text-xl mt-4 mb-10 text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                        Il nostro servizio di consegna e ritiro ti permette di risparmiare tempo prezioso, offrendo una soluzione comoda e flessibile per gestire le tue necessità farmaceutiche. 
                        </p>
                        {/* <a href='/' >
                            <button className={`${width > 768 ? 'progress-button-light border-slate-50 text-slate-50' : 'border-black text-black progress-button-dark'}  px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full lg:w-1/2`}>
                            SCOPRI di più
                            </button>
                        </a> */}
                        </div>
                    </div>
                </div>
            </div>

            {width > 768 ? <FullBWSection 
                background='bg-black'
                text='text-slate-50'
                buttonClass1='hidden'
                buttonClass2='hidden'
                darkButton={true}
                href2=''
                href='/'
                title='Controlla la disponibilità'
                description={`
                    <p class=''>Trova ciò di cui hai bisogno in pochi secondi. Con il nostro sistema di controllo disponibilità, puoi verificare in tempo reale se il farmaco che cerchi è presente nella farmacia più vicina a te. </p>
                    `}
                image={img7}
                opacity='opacity-50'
                translate='lg:translate-x-[20%]'
                firstButtonText='Scopri di più'
            /> :
            <FullMinimalSection 
                headerImage={img7}
                logoTransp=''
                title='Controlla la disponibilità'
                description={`
                    <p class=''>Trova ciò di cui hai bisogno in pochi secondi. Con il nostro sistema di controllo disponibilità, puoi verificare in tempo reale se il farmaco che cerchi è presente nella farmacia più vicina a te. </p>
                    `}
                logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
                buttonText='Scopri di più'
                darkButton={true}
                download={false}
                buttonClass='hidden'
                buttonClass2='hidden'
                href='/'
            />
            }

            <div className='relative z-20 h-lvh overflow-hidden'>
                <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                    <img src={img8} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
                </ScrollParallax>
                <div className="absolute top-0 left-0 lg:bg-black lg:bg-opacity-50 bg-blue-oxyera w-full h-full flex justify-center items-center">
                    {/* Text container centered and slightly moved to the right */}
                    <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                        <div className='lg:w-[50%]'>
                        <h2 className="lg:text-5xl text-4xl text-white font-semibold drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                        Visite ed esami
                        </h2>
                        <p className="text-xl mt-4 mb-10 text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                        Prenota visite ed esami senza muoverti da casa. Che si tratti di un check-up di routine, di un esame specialistico o di una consulenza con un esperto, bastano pochi clic per fissare il tuo appuntamento.
                        </p>
                        {/* <a href='/' >
                            <button className={`${width > 768 ? 'progress-button-light border-slate-50 text-slate-50' : 'border-black text-black progress-button-dark'}  px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full lg:w-1/2`}>
                            SCOPRI di più
                            </button>
                        </a> */}
                        </div>
                    </div>
                </div>
            </div>

            {width > 768 ? <FullBWSection 
                background='bg-black'
                text='text-slate-50'
                buttonClass1='hidden'
                buttonClass2='hidden'
                darkButton={true}
                href2=''
                href='/'
                title='Strumenti di Analisi delle Performance'
                description={`
                    <p class=''>Identificare opportunità di crescita basate sull'andamento delle vendite e sulle tendenze di mercato</p>
                     <img src="${coomingSoon}" alt="" class='w-2/5 z-40'/> 
                    `}
                image={img9}
                opacity='opacity-50'
                translate='lg:translate-x-[20%]'
                firstButtonText='Scopri di più'
            /> :
            <FullMinimalSection 
                headerImage={img9}
                logoTransp=''
                title='Strumenti di Analisi delle Performance'
                description={`
                    <p class=''>Identificare opportunità di crescita basate sull'andamento delle vendite e sulle tendenze di mercato</p>
                    <img src="${coomingSoon}" alt="" class='w-1/2 lg:hidden z-40 block'/> 
                    `}
                logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
                buttonText='Scopri di più'
                darkButton={true}
                download={false}
                buttonClass='hidden'
                buttonClass2='hidden'
                href='/'
            />
            }

            <Footer />
        </>
    );
}

export default Servizi;
