
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import { Link } from "react-router-dom";
import { IoCartOutline, IoCloseCircle, IoInformationCircle } from "react-icons/io5";
import { useAuth } from '../../context/AuthContext';
import oxyeraIcon from '../../assets/icons/android-chrome-192x192.png';
import axios from "axios";
import { BiCartAdd, BiCheck } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import Lottie from 'react-lottie';
import * as loadingAnimation from '../../assets/lottie/loading-white.json';
import debounce from 'lodash/debounce';
import OrderPopUp from "./component/OrderPopUp";
import LoginPopUp from "../../components/login/PopUp";
import DetailPopUp from "./component/DetailPopUp";


function ProdottiMobile() {
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addedItemId, setAddedItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(0);
  const { cart, setCart } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const location = useLocation();
  const accessToken = sessionStorage.getItem('access_token');
  const params = new URLSearchParams(location.search);
  const query = params.get('search');
  const [isDetailPopUp, setDetailIsPopUp] = useState(false);
  const [selectedAIC, setSelectedAIC] = useState(null);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleSearch = async () => {
    setIsLoading(1);
    if (searchTerm.length > 0) {
      try {
        const response = await axios.get(`https://app.oxyera.com/medicine/search/${searchTerm}`);
        setSuggestions(response.data);

        if (response.data.length === 0) {
          setIsLoading(2);
          return;
        }

        setIsLoading(0);

      } catch (error) {
        setIsLoading(2);
        console.error("Error fetching search results", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '') {
      setSuggestions([]);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length > 1) {
        handleSearch();
      } else {
        setSuggestions([]);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);


  const consolidateCartItems = (cart) => {
    const consolidated = {};

    cart.forEach(item => {
      const quantity = item.quantity || 1;

      if (consolidated[item.AIC]) {
        consolidated[item.AIC].quantity += quantity;
      } else {
        consolidated[item.AIC] = { ...item, quantity };
      }
    });

    return Object.values(consolidated);
  };

  const consolidatedCart = consolidateCartItems(cart);

  const fetchCategoryData = async (categoryCode) => {
    try {
      const response = await axios.get(`https://app.oxyera.com/medicine/category/${categoryCode}`);

      if(response.status === 200){
        setSuggestions(response.data);
        return;
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
      throw error;
    }
  };

  
  const handleAddToCart = useCallback(debounce((product) => {
    setCart((prevCart) => {
      console.log('Previous Cart:', prevCart);

      const existingItemIndex = prevCart.findIndex(item => item.AIC === product.AIC);

      if (existingItemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += 1;
        console.log('Updated Cart with Incremented Quantity:', updatedCart);
        return updatedCart;
      } else {
        const updatedCart = [...prevCart, { ...product, quantity: 1 }];
        console.log('Updated Cart with New Item:', updatedCart);
        return updatedCart;
      }
    });

    setAddedItemId(product.AIC);

    setTimeout(() => {
      setAddedItemId(null);
    }, 1000);
  }, 300), []);

  const handleCompleteOrder = () => {
    if (accessToken) {
      setIsPopupOpen(true);
    } else {
      setIsLoginOpen(true);
    }
  };

  const closeCompleteOrder = () => {
    setIsPopupOpen(false);
  };

  const loginStatus = () => {
    setIsLoginOpen(false);
    if(cart.length > 0 && accessToken){
      setIsPopupOpen(true);
      return;
    }
  }


  useEffect(() => {
    if (query) {
      setSearchTerm(query);
      handleSearch(query);
      return;
    } 
    fetchCategoryData('G');
    document.body.style.overflow = 'auto'; 
  }, [query]);

  const closeDetailPopUp = () => {
    setDetailIsPopUp(false);
    setSelectedAIC(null);
  };
  
  const openDetailPopUp = (product) => {
    setSelectedAIC(product.AIC); 
    setDetailIsPopUp(true);
  };

    return (
        <>
            <Helmet>
                <title>Prodotti - Oxyera</title>
                <meta name="description" content="Description of the prodotti page" />
                <meta property="og:title" content="Prodotti - Oxyera" />
                <meta property="og:description" content="Description of the prodotti page" />
                <meta property="og:image" content={Logo} /> 
                <meta property="og:url" content="https://oxyera.com/prodotti" />
                <meta property="og:type" content="website" /> 
                <meta name="keywords" content="oxyera, prodotti, health" />
                <meta name="author" content="Oxyera" />
                <link rel="canonical" href="https://oxyera.com/prodotti" />
            </Helmet>

            <div className="flex flex-col bg-blue-oxyera relative">
              {/* Header with Logo and Search Bar */}
              <div className="px-4 flex flex-col items-center py-4">
                <div className="flex items-center justify-between w-full">
                  <Link className="flex items-center" to="/">
                    <img src={Logo} alt="Logo" className="h-16 w-auto mr-3" />
                    <h1 className="text-2xl font-semibold text-white">Oxyera</h1>
                  </Link>
                  <button className="relative flex items-center mx-2" onClick={handleCompleteOrder}>
                    <IoCartOutline className='text-3xl text-white' />
                    {cart.length > 0 && (
                      <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs rounded-full w-6 h-6 flex items-center justify-center">
                        {cart.length}
                      </span>
                    )}
                  </button>
                </div>

                {/* Search Bar */}
                <div className="relative flex items-center w-full mt-4">
                  <input
                    type='text'
                    placeholder='Cerca un prodotto'
                    className='w-full px-4 py-2 border border-gray-300 rounded-l-lg text-lg focus:outline-none'
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className={`absolute right-24 text-gray-500 ${searchTerm ? 'visible' : 'invisible'}`}
                    onClick={clearSearch}
                  >
                    <IoCloseCircle className='text-gray-400 text-xl' />
                  </button>
                  <button
                    className='bg-orange-500 text-white px-4 py-2 rounded-r-lg text-lg font-semibold'
                    onClick={handleSearch}
                  >
                    Cerca
                  </button>
                </div>
              </div>

              {/* Product Grid */}
              <div className="flex-grow px-4 py-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3" style={{ overflowY: 'auto' }}>
                {isLoading === 1 ? (
                  <Lottie options={defaultOptions} height={100} width={100} />
                ) : suggestions.length > 0 ? (
                  suggestions.map(product => (
                    <div
                      key={product.id}
                      className="bg-white p-2 rounded-lg shadow flex items-center space-x-4 hover:shadow-lg transition-shadow"
                      onClick={() => openDetailPopUp(product)}
                    >
                        {/* Image on the Left */}
                        <img
                          src={product.url || oxyeraIcon}
                          alt='farmaco'
                          className="w-16 h-16 object-contain rounded"
                        />
        
                        {/* Text in the Center */}
                        <div className="flex flex-col items-start justify-start text-start flex-grow">
                          <h3 className="text-sm font-semibold">{product.drug.split('*')[0]}</h3>
                          <p className="text-gray-800 text-xs">{product.drug.split('*')[1]}</p>
                          <div className="flex flex-row justify-center items-center mb-3 mt-2">
                            <p className="text-gray-500">{product.public_price.toFixed(2)}€</p>
                            <div className="relative group ml-2">
                              <span className="text-blue-oxyera cursor-pointer">
                                <IoInformationCircle />
                              </span>
                              {/* <div
                                className="absolute left-1/2 bottom-full transform -translate-x-1/2 mb-2 w-48 p-2 bg-gray-700 text-white text-sm rounded hidden group-hover:block z-50"
                              >
                                Il prezzo indicato è quello consigliato al pubblico
                              </div> */}
                            </div>
                          </div>
                        </div>                      
                      {/* Add Button on the Right */}
                      <button
                        onClick={() => handleAddToCart(product)}
                        className="py-2 px-2 bg-blue-oxyera text-white rounded hover:opacity-75 transition-colors flex items-center justify-center"
                      >
                        {addedItemId === product.AIC ? (
                          <BiCheck className='text-lg text-white' />
                        ) : (
                          <BiCartAdd className='text-lg text-white' />
                        )}
                      </button>
                    </div>
                  ))
                ) : isLoading === 2 && (
                  <p className="text-white text-xl font-semibold text-center">
                    Nessun risultato trovato. <br />Assicurati di aver digitato correttamente e prova di nuovo.
                  </p>
                )}
              </div>

              {/* Fixed Button to Complete Order */}
              <div className="fixed bottom-0 left-0 w-full px-4 py-3 bg-orange-500 text-center z-10">
                <button className="w-full py-3 text-lg font-semibold text-white rounded-lg flex flex-row justify-center" onClick={handleCompleteOrder}>
                    <IoCartOutline className='text-3xl text-white me-3' />
                  Completa Ordine: {consolidatedCart.reduce((total, item) => total + item.public_price * item.quantity, 0).toFixed(2)}€
                </button>
              </div>

              {/* Popup */}
              {isDetailPopUp && <DetailPopUp onClose={closeDetailPopUp} aic={selectedAIC} />}
              {isPopupOpen && <OrderPopUp onClose={closeCompleteOrder} />}
              <LoginPopUp
                isOpen={isLoginOpen}
                onClose={loginStatus}
              />
            </div>

        </>
    );
}

export default ProdottiMobile;
