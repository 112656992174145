import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import { CiShoppingCart } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { IoChevronBack, IoCloseCircle } from "react-icons/io5";
import { useAuth } from '../../context/AuthContext';
import oxyeraIcon from '../../assets/icons/android-chrome-192x192.png';
import axios from "axios";
import { BiCartAdd, BiCheck } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import supplements from '../../assets/categorieoxyiconscategories/supplements.png';
import allergy from '../../assets/categorieoxyiconscategories/allergy.png';
import probiotics from '../../assets/categorieoxyiconscategories/probiotics.png';
import cold from '../../assets/categorieoxyiconscategories/cold.png';
import cough from '../../assets/categorieoxyiconscategories/cough.png';
import pain from '../../assets/categorieoxyiconscategories/pain.png';
import diabetes from '../../assets/categorieoxyiconscategories/diabetes.png';
import vet from '../../assets/categorieoxyiconscategories/vet.png';
import acidity from '../../assets/categorieoxyiconscategories/acidity.png';
import fever from '../../assets/categorieoxyiconscategories/fever.png';
import headache from '../../assets/categorieoxyiconscategories/headache.png';
import stomach from '../../assets/categorieoxyiconscategories/stomach.png';
import homeopathy from '../../assets/categorieoxyiconscategories/homeopathy.png';
import Lottie from 'react-lottie';
import * as loadingAnimation from '../../assets/lottie/loading-white.json';  // Add your Lottie JSON file
import { FaTrashAlt } from 'react-icons/fa'; // Importing the trash icon
import debounce from 'lodash/debounce';
import OrderPopUp from "./component/OrderPopUp";
import LoginPopUp from "../../components/login/PopUp";
import { IoInformationCircle } from "react-icons/io5";
import DetailPopUp from "./component/DetailPopUp";



function Prodotti() {
    const [isCartOpen, setIsCartOpen] = useState(false); // For right sidebar toggle state
    const [suggestions, setSuggestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [addedItemId, setAddedItemId] = useState(null);  // State to track the recently added item
    const [isLoading, setIsLoading] = useState(0);  
    const { cart, setCart } = useAuth(); // Ensure setCart is available
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDetailPopUp, setDetailIsPopUp] = useState(false);
    const [selectedAIC, setSelectedAIC] = useState(null);
    const [isLoginOpen, setIsLoginOpen] = useState(false); // State for Login Pop-up
    const location = useLocation(); // Hook to access the location object
    const [selectedCategory, setSelectedCategory] = useState(null);
    const params = new URLSearchParams(location.search);
    const query = params.get('search');
    const accessToken = sessionStorage.getItem('access_token');


    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loadingAnimation.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    
    const handleSearch = async () => {
      setIsLoading(1)
        if (searchTerm.length > 0) {
          try {
            const response = await axios.get(`https://app.oxyera.com/medicine/search/${searchTerm}`);
            setSuggestions(response.data);
            setSelectedCategory(null);
            if(response.data.length === 0){
              setIsLoading(2);
              return;
            }

            setIsLoading(0);

          } catch (error) {
              setIsLoading(2);
            
            console.error("Error fetching search results", error);
          }
        } else {
          setSuggestions([]);
        }
      };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // Trigger button click
          handleSearch();
        }
      };

      const handleInputChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
    
        if (term === '') {
          setSuggestions([]); // Clear suggestions when the input is cleared
        }
      };

      const clearSearch = () => {
        setSearchTerm('');  // Clear the search field
        setSuggestions([]); // Clear the dropdown suggestions
      };
    
      useEffect(() => {
        // Debounce logic
        console.log(suggestions)
        const handler = setTimeout(() => {
          if (searchTerm.length > 1) {
            handleSearch();
          } else {
            setSuggestions([]);
          }
        }, 300); // Debounce delay (300ms)
    
        return () => {
          clearTimeout(handler);
        };
      }, [searchTerm]);

      useEffect(() => {
        if (query) {
            setSearchTerm(query);
            handleSearch(query); // Trigger the API call with the query parameter
        } else {
            // If no search parameter, fetch default category data
            fetchCategoryData('g');
            setSelectedCategory('G');
        }
    }, [location.search]); // Dependency array ensures this runs only when location.search changes


    // Function to consolidate items by 'aic'
    const consolidateCartItems = (cart) => {
        const consolidated = {};
        cart.forEach(item => {
          // Ensure quantity is initialized and debug if it's missing
          const quantity = item.quantity || 1; // Default to 1 if quantity is not provided
    
          if (consolidated[item.AIC]) {
            // Increase quantity if item already exists
            consolidated[item.AIC].quantity += quantity;
          } else {
            // Initialize item with quantity
            consolidated[item.AIC] = { ...item, quantity };
          }
        });
        
        return Object.values(consolidated);
      };
    
      // Consolidate cart items
      const consolidatedCart = consolidateCartItems(cart);
      
      useEffect(() => {
        if(cart.length === 1){
          setIsCartOpen(true);
          return;
        }
        if(cart.length === 0){
          setIsCartOpen(false);
          return;
        }
      },[cart])

    const categoriesHome = [
        {
          titleIt: 'Integratori',
          titleEn: 'Supplements',
          categoryCode: 'G',
          image: supplements,
        },
        {
          titleIt: 'Allergie',
          titleEn: 'Allergies',
          categoryCode: 'A0103',
          image: allergy,
        },
        {
          titleIt: 'Fermenti',
          titleEn: 'Probiotics',
          categoryCode: 'A0332030101',
          image: probiotics,
        },
        {
          titleIt: 'Raffreddore',
          titleEn: 'Cold',
          categoryCode: 'A0420040101',
          image: cold,
        },
        {
          titleIt: 'Tosse',
          titleEn: 'Cough',
          categoryCode: 'A0438',
          image: cough,
        },
        {
          titleIt: 'Dolori',
          titleEn: 'Pain',
          categoryCode: 'A0539',
          image: pain,
        },
        {
          titleIt: 'Diabete',
          titleEn: 'Diabetes',
          categoryCode: 'W3434',
          image: diabetes,
        },
        {
          titleIt: 'Veterinaria',
          titleEn: 'Vet',
          categoryCode: 'V2040',
          image: vet,
        },
        {
          titleIt: 'Acidità',
          titleEn: 'Acidity',
          categoryCode: 'A033104',
          image: acidity,
        },
        {
          titleIt: 'Febbre',
          titleEn: 'Fever',
          categoryCode: 'A042002',
          image: fever,
        },
        {
          titleIt: 'Mal di testa',
          titleEn: 'Headache',
          categoryCode: 'A052302',
          image: headache,
        },
        {
          titleIt: 'Stomaco',
          titleEn: 'Stomach',
          categoryCode: 'A03',
          image: stomach,
        },
        {
          titleIt: 'Omeopatia',
          titleEn: 'Homeopathy',
          categoryCode: 'A27',
          image: homeopathy,
        },
    ];
      
      const fetchCategoryData = async (categoryCode) => {
        setSelectedCategory(categoryCode);
        setSearchTerm('');

        console.log(categoryCode);
        try {
          const response = await axios.get(`https://app.oxyera.com/medicine/category/${categoryCode}`);
          setSuggestions(response.data)
          return response.data;
        } catch (error) {
          console.error('Error fetching category data:', error);
          throw error;
        }
      };      
    

      // Debounced version of handleAddToCart
      const handleAddToCart = useCallback(debounce((product) => {
          setCart((prevCart) => {
              console.log('Previous Cart:', prevCart);
              
              const existingItemIndex = prevCart.findIndex(item => item.AIC === product.AIC);
              
              if (existingItemIndex !== -1) {
                  // Item exists, increment quantity
                  const updatedCart = [...prevCart];
                  updatedCart[existingItemIndex].quantity += 1;
                  console.log('Updated Cart with Incremented Quantity:', updatedCart);
                  return updatedCart;
              } else {
                  // Item does not exist, add with quantity 1
                  const updatedCart = [...prevCart, { ...product, quantity: 1 }];
                  console.log('Updated Cart with New Item:', updatedCart);
                  return updatedCart;
              }
          });
      
          setAddedItemId(product.AIC);
      
          setTimeout(() => {
              setAddedItemId(null);
          }, 1000);

      }, 300), []);
          
    

// Function to increase quantity
const handleIncreaseQuantity = (aic) => {
  setCart((prevCart) => {
      return prevCart.map(item => 
          item.AIC === aic 
          ? { ...item, quantity: item.quantity + 1 } 
          : item
      );
  });
};

// Function to decrease quantity
const handleDecreaseQuantity = (aic) => {
  setCart((prevCart) => {
      return prevCart
          .map(item => 
              item.AIC === aic 
              ? { ...item, quantity: item.quantity - 1 } 
              : item
          )
          .filter(item => item.quantity > 0); // Remove item if quantity is 0 or less
  });
};

// Function to remove an item completely from the cart
const handleRemoveFromCart = (aic) => {
  setCart((prevCart) => {
      return prevCart.filter(item => item.AIC !== aic);
  });
};

const handleCompleteOrder = () => {
  // setIsPopupOpen(true);
  if (accessToken) {
      setIsPopupOpen(true);
  } else {
    setIsLoginOpen(true);
  }
};

const closeCompleteOrder = () => {
  setIsPopupOpen(false);
};

const closeDetailPopUp = () => {
  setDetailIsPopUp(false);
  setSelectedAIC(null);
};

const openDetailPopUp = (product) => {
  setSelectedAIC(product.AIC); 
  setDetailIsPopUp(true);
};

const loginStatus = () => {
  setIsLoginOpen(false);
  if(cart.length > 0 && accessToken){
    setIsPopupOpen(true);
    return;
  }
}


    return (
        <>
            <Helmet>
                <title>Prodotti - Oxyera</title>
                <meta name="description" content="Description of the prodotti page" />
                <meta property="og:title" content="Prodotti - Oxyera" />
                <meta property="og:description" content="Description of the prodotti page" />
                <meta property="og:image" content={Logo} /> 
                <meta property="og:url" content="https://oxyera.com/prodotti" />
                <meta property="og:type" content="website" /> 
                <meta name="keywords" content="oxyera, prodotti, health" />
                <meta name="author" content="Oxyera" />
                <link rel="canonical" href="https://oxyera.com/prodotti" />
            </Helmet>

            <div className="flex min-h-screen bg-blue-oxyera">
                {/* Left Sidebar */}
                <div className="w-1/5 bg-gray-100 h-screen flex flex-col z-40">
                <div className="flex flex-row justify-start items-center m-5">
                    <IoChevronBack className="me-1 text-sm" />
                    <Link to="/">Torna indietro</Link>
                </div>
                <h2 className="text-xl font-semibold mb-2 ms-5 text-start">Categorie</h2>
                <div className="flex-1 overflow-y-auto no-scrollbar">
                    <ul className="">
                    {categoriesHome.map((category) => (
                        <li key={category.categoryCode} className={`flex items-center px-8 py-4 cursor-pointer hover:bg-[#04899e37] ${category.categoryCode === selectedCategory && 'bg-[#04899e52]'}`} onClick={() => fetchCategoryData(category.categoryCode)}>
                        <img
                            src={category.image}
                            alt={category.titleIt}
                            className="w-12 h-12 object-contain mr-2"
                        />
                        <p className="text-sm font-semibold">{category.titleIt}</p>
                        </li>
                    ))}
                    </ul>
                </div>
                </div>


                {/* Main Content */}
                <div className={`px-4 flex flex-col transition-all duration-300 h-screen ${isCartOpen ? 'w-3/5' : 'w-4/5'}`}>
                    {/* Search Bar */}
                    <div className="flex flex-col items-start ps-10">
                      <div className="relative flex items-center w-11/12 my-4">
                        <div className="flex justify-start items-center w-56 me-0 sme-2 me-10">
                          <img src={Logo} alt="Logo" className="h-24 w-auto" />
                          <h1 className="ml-3 text-4xl font-semibold text-white">Oxyera</h1>
                        </div>
                        <input 
                            type='text' 
                            placeholder='Cerca un prodotto' 
                            className='flex-grow px-4 py-2 border border-gray-300 rounded-l-lg text-lg'
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button 
                            className={`absolute right-24 text-gray-500 ${searchTerm ? 'visible' : 'invisible'}`}
                            onClick={clearSearch}
                        >
                            <IoCloseCircle className='text-gray-400 text-xl' />
                        </button>
                        <button 
                            className='bg-orange-500 text-white px-4 py-2 rounded-r-lg text-lg font-semibold'
                            onClick={handleSearch}
                        >
                            Cerca
                        </button>
                      </div>


                    </div>

                    {/* Product Grid */}
                    <div className={`grid gap-4 no-scrollbar p-10 ${isCartOpen ? 'grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'}`} style={{ overflowY: 'auto' }}>
                        {suggestions.length > 0 && 
                            suggestions.map(product => (
                                <div key={product.id} className="bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow flex flex-col h-full">
                                <button onClick={() => openDetailPopUp(product)}>
                                  <img 
                                      src={product.url || oxyeraIcon} 
                                      alt='farmaco' 
                                      className="w-full h-24 object-contain rounded mb-2" 
                                  />
                                  <h3 className="text-lg font-semibold">{product.drug.split('*')[0]}</h3>
                                  <p className="text-gray-800">{product.drug.split('*')[1]}</p>
                                  <div className="flex flex-row justify-center items-center mb-3 mt-2">
                                    <p className="text-gray-500">{product.public_price.toFixed(2)}€</p>
                                    <div className="relative group ml-2">
                                      <span className="text-blue-oxyera cursor-pointer">
                                        <IoInformationCircle />
                                      </span>
                                      <div
                                        className="absolute left-1/2 bottom-full transform -translate-x-1/2 mb-2 w-48 p-2 bg-gray-700 text-white text-sm rounded hidden group-hover:block z-50"
                                      >
                                        Il prezzo indicato è quello consigliato al pubblico
                                      </div>
                                    </div>
                                  </div>
                                </button>
                                <button 
                                    onClick={() => handleAddToCart(product)} 
                                    className="flex flex-row mt-auto w-full justify-center py-2 bg-blue-oxyera text-white rounded hover:opacity-75 transition-colors"
                                >
                                    {addedItemId === product.AIC ? (
                                        <BiCheck className='text-2xl me-4 text-white' />  // Show the check icon
                                      ) : (
                                        <BiCartAdd className='text-2xl me-4 text-white' />  // Show the cart icon
                                    )} 
                                    Aggiungi
                                </button>
                                </div>

                            ))
                        }
                    </div>
                      {isLoading === 1 ? (
                            <Lottie options={defaultOptions} height={100} width={100} />
                        ) : isLoading === 2 && (
                              <p className="text-white text-xl text-semibold">Nessun risultato trovato. <br/>Assicurati di aver digitato correttamente e prova di nuovo.</p>
                      )}
                    <div className="absolute inset-x-0 top-[8rem] h-8 bg-gradient-to-b from-[#04899e] to-transparent pointer-events-none" />
                     <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-[#04899e] to-transparent pointer-events-none" />
                </div>

                {/* Right Sidebar */}
                <div className={`z-40 bg-white flex flex-col h-screen transition-all duration-300 ${isCartOpen ? 'w-1/5' : 'w-0 overflow-hidden'}`}>
                    <button onClick={() => setIsCartOpen(!isCartOpen)} className={`absolute top-5 right-5 transform ${isCartOpen ? 'hidden' : 'text-white'} transition-transform`}>
                        <CiShoppingCart className="text-4xl" />
                        {cart.length > 0 && (
                            <span className="absolute -top-3 -right-4 bg-red-600 text-white text-[10px] rounded-full px-2 py-1">
                                {cart.length}
                            </span>
                        )}
                    </button>
                    {isCartOpen && (
                        <>
                            <div className="flex flex-row items-start justify-between">
                                <div className="flex flex-row items-center">
                                    <button onClick={() => setIsCartOpen(!isCartOpen)}>
                                        <TfiClose className="text-lg m-3"/>
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-center mb-5">
                                <h2 className="text-xl">Il tuo Ordine</h2>
                            </div>
                            <div className="flex-1 overflow-y-auto no-scrollbar">
                              <ul className="flex-1 overflow-y-auto text-start no-scrollbar text-center">
                                  {consolidatedCart.length > 0 ? (
                                      consolidatedCart.map(item => (
                                        <li key={item.AIC} className="relative flex items-center p-4 shadow-sm w-full h-full">
                                            {/* Product Image */}
                                            <img
                                                src={item.url || oxyeraIcon}
                                                alt={item.drug}
                                                className="w-10 h-10 mr-4 object-cover"
                                            />
                                            {/* Product Details */}
                                            <div className="flex-grow flex flex-col">
                                                <p className="text-sm font-semibold w-[10rem]">{item.drug.split('*')[0]}</p>
                                                <p className="text-sm">{item.drug.split('*')[1]}</p>
                                                <div className="flex items-center mt-1">
                                                    <span className="me-3">Qtà: </span>
                                                    {/* Quantity Controls */}
                                                    <button 
                                                        onClick={() => handleDecreaseQuantity(item.AIC)} 
                                                        className="bg-[#04899e] bg-opacity-25 rounded-full w-[1.5rem] h-[1.5rem] me-2"
                                                    >
                                                        -
                                                    </button>
                                                    <span className="text-md font-medium">{item.quantity}</span>
                                                    <button 
                                                        onClick={() => handleIncreaseQuantity(item.AIC)} 
                                                        className="bg-[#04899e] bg-opacity-25 rounded-full w-[1.5rem] h-[1.5rem] ms-2"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Absolute Positioned Price and Trash Icon */}
                                            <span className="text-sm absolute right-2 top-4">{item.public_price.toFixed(2)}€</span>
                                            <FaTrashAlt 
                                                className="absolute right-2 bottom-4 text-red-600 cursor-pointer hover:text-red-800 transition-colors"
                                                onClick={() => handleRemoveFromCart(item.AIC)} 
                                            />
                                        </li>

                                      ))
                                  ) : (
                                      <p className="text-center">Il carrello è vuoto</p>
                                  )}
                              </ul>
                            </div>
                            {/* Total Price Button */}
                              <button className="flex justify-between items-center px-8 py-3 bg-orange-500 rounded-full m-5 text-white" onClick={handleCompleteOrder}>
                                  <span className="font-semibold flex flex-row items-center justify-start">
                                  <CiShoppingCart className="text-3xl me-2" />
                                  Acquista:</span>
                                  <span className="text-xl font-semibold">
                                      {consolidatedCart.reduce((total, item) => total + item.public_price * item.quantity, 0).toFixed(2)}€
                                  </span>
                              </button>
                        </>
                    )}
                </div>

            </div>
            {isDetailPopUp && <DetailPopUp onClose={closeDetailPopUp} aic={selectedAIC} />}
            {isPopupOpen && <OrderPopUp onClose={closeCompleteOrder} />}
            <LoginPopUp 
                isOpen={isLoginOpen} 
                onClose={loginStatus} 
            />
        </>
    );
}

export default Prodotti;
