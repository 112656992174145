import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import Logo from "../assets/logo/OXYERA_Logo_nbg.png";

function TermOfCondition() {
  return (
    <div>
      <Navbar />

      {/* Privacy Policy Page */}
      <section className="bg-blue-oxyera min-h-screen py-20">
        <div className="max-w-4xl mx-auto p-6 sm:p-10">
          {/* Header with Logo */}
          <div className="flex flex-col items-center justify-center mb-8">
            <img src={Logo} alt="Oxyera Logo" className="w-16 h-16 mr-4" />
            <h1 className="text-4xl font-semibold text-white">INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI OXYERA</h1>
          </div>

          {/* Introduction */}
          <p className="text-lg text-white mb-6 leading-relaxed">
          Oxyera Società Benefit S.r.l., con sede legale in Piazza Galeazzo Alessi, 2/7, 16128 Genova GE, e partita IVA 02966660991, in qualità di titolare del trattamento dei dati personali, ai sensi del Regolamento UE 2016/679 (GDPR) e del Decreto Legislativo 196/2003 (Codice in materia di protezione dei dati personali), informa gli utenti e i visitatori del sito web di Oxyera (di seguito "Utenti") in merito al trattamento dei loro dati personali.
          </p>

          {/* Sections */}
          <div className="space-y-8">
            {/* Section 1: Information Collection */}
            <div className='text-start'>
                  <p className="text-lg text-white font-bold leading-relaxed">
                    Premesse:
                  </p>
                  <ul className='text-white text-start list-disc ms-10'>
                    <li>
                    Oxyera Società Benefit S.r.l., è attivamente impegnata nello sviluppo dei servizi delineati nell'oggetto sociale del proprio Statuto. Specificamente, la società offre una vetrina digitale per le farmacie, progettata per fornire servizi farmaceutici agli utenti in modalità digitale. Tale servizio è accessibile tramite una mobile app e una piattaforma web destinate agli utenti, oltre a una piattaforma gestionale per le farmacie che consente la gestione degli ordini.
                    </li>
                    <li>
                    Questa piattaforma si distingue per le sue funzionalità innovative, integrate con le tecnologie dell'Industria 4.0, volte a semplificare gli ordini presso le farmacie (e per le versioni successive del servizio, la consegna a domicilio previa dotazione di mezzi autorizzati al trasporto dei prodotti e la prenotazione di esami e visite mediche).
                    </li>
                  </ul>
            </div>

            {/* Section 2: How We Use Your Information */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Titolari del Trattamento</h2>
              <p className="text-lg text-white leading-relaxed">
              La startup Oxyera agisce in qualità di titolare del trattamento.
              </p>
            </div>

            {/* Section 3: Information Sharing and Disclosure */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Responsabile del Trattamento dati</h2>
              <p className="text-lg text-white leading-relaxed">
              Mario Alessandro Fiore – Project Consult Srl – dpo@leggesullaprivacy.it
              </p>
            </div>

            {/* Section 4: Security of Your Information */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Destinatari del trattamento dei dati personali</h2>
              <p className="text-lg text-white leading-relaxed">
              I destinatari del presente trattamento dei dati personali sono rappresentati da tutte le società terze alle quali il titolare si appoggia per garantire l’erogazione del servizio ( fornitori di servizi tecnici terzi, hosting provider, società informatiche, agenzie di comunicazione) nonché dalle farmacia aderenti al progetto.
              </p>
            </div>

            {/* Section 5 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Tipologia di Dati Raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              I dati personali raccolti includono, a titolo esemplificativo, nome, cognome, codice fiscale, indirizzo, e-mail, numero di telefono, informazioni sugli ordini effettuati e dati di pagamento.
              </p>
            </div>

            {/* Section 6 */}
            <div className='text-start'>
              <h2 className="text-2xl font-bold text-white mb-4">Finalità del Trattamento</h2>
              <p className="text-lg text-white leading-relaxed">
              I dati personali dell'interessato saranno utilizzati esclusivamente per:
              </p>
              <ul className='text-white text-start list-disc ms-10'>
                <li>
                L'erogazione del servizio, inclusa la gestione degli ordini e la consegna dei farmaci.
                </li>
                <li>
                La comunicazione con l'utente per qualsiasi necessità legata al servizio.
                </li>
                <li>
                La raccolta di feedback durante la fase di prova del servizio.
                </li>
              </ul>
            </div>

            {/* Section 7 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Base Giuridica del Trattamento</h2>
              <p className="text-lg text-white leading-relaxed">
              Accettando i termini e le condizioni del servizio di cui in oggetto, l’ utente diviene parte di un contratto con il Titolare del trattamento. Pertanto la base giuridica di quest’ultimo è rappresentata dal contratto (
                ex art. 6 lett. b GDPR 2016/679). Inoltre il Titolare potrà trattare taluni dati al fine di adempiere (ex art. 6 lett. c GDPR 2016/679) ad un obbligo legale a cui è sottoposto.
              </p>
            </div>

            {/* Section 8 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Modalità del Trattamento</h2>
              <p className="text-lg text-white leading-relaxed">
              I dati personali dell’ interessato sono trattati con strumenti automatizzati e non automatizzati, adottando misure tecniche e organizzative adeguate a garantire la sicurezza e la riservatezza dei dati alla luce del principio di accountability adottato dal Reg n 679/2016.
              </p>
            </div>


            {/* Section 9 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Soggetti che possono avere accesso ai dati raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. In tutti i casi, quest’ultimo ha impartito precise indicazioni, relative al trattamento dati di cui in oggetto, previo idonei accordi di riservatezza, a cui sono stati sottoposti tutti i propri dipendenti, e adeguati contratti di trattamento dati per i soggetti terzi a cui il Titolare si affida per la fornitura del servizio.
              </p>
            </div>

            {/* Section 10 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Conservazione dei Dati</h2>
              <p className="text-lg text-white leading-relaxed">
              I dati personali dell’interessato saranno conservati per il periodo strettamente necessario a soddisfare le finalità del trattamento e comunque non oltre 5 anni dall'ultima interazione con l'interessato, salvo diversa disposizione di legge. Una volta che i dati non saranno più necessari, verranno cancellati in modo sicuro. Si precisa che la conservazione dei dati avviene all'interno dell'Unione Europea.
              </p>
            </div>

            
            {/* Section 11 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Condivisione dei Dati</h2>
              <p className="text-lg text-white leading-relaxed">
              I dati personali dell’ interessato saranno condivisi con le farmacie convenzionate esclusivamente per finalità legate all'erogazione del servizio offerto da Oxyera. Ogni farmacia tratterà i dati secondo le proprie politiche di privacy, in conformità con la normativa vigente.
              </p>
            </div>

            {/* Section 12 */}
            <div className='text-start'>
              <h2 className="text-2xl font-bold text-white mb-4">Diritti del soggetto del trattamento</h2>
              <ol className='list-decimal text-white text-start'>
                <li>
                  <p className="text-lg text-white leading-relaxed">
                  L'interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:
                  </p>
                  <ul className='text-white text-start list-disc ms-10'>
                    <li>
                    a) le finalità del trattamento;
                    </li>
                    <li>
                    b) le categorie di dati personali in questione;
                    </li>
                    <li>
                    c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
                    </li>
                    <li>
                    d) quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;
                    </li>
                    <li>
                    e) l'esistenza del diritto dell'interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;
                    </li>
                    <li>
                    f) il diritto di proporre reclamo a un'autorità di controllo;
                    </li>
                    <li>
                    g) qualora i dati non siano raccolti presso l'interessato, tutte le informazioni disponibili sulla loro origine;
                    </li>
                    <li>
                    h) l'esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all'articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato.
                    </li>
                  </ul>
                </li>
                <li>
                Qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, l'interessato ha il diritto di essere informato dell'esistenza di garanzie adeguate ai sensi dell'articolo 46 relative al trasferimento.
                </li>
                <li>
                Il titolare del trattamento fornisce una copia dei dati personali oggetto di trattamento. In caso di ulteriori copie richieste dall'interessato, il titolare del trattamento può addebitare un contributo spese ragionevole basato sui costi amministrativi. Se l'interessato presenta la richiesta mediante mezzi elettronici, e salvo indicazione diversa dell'interessato, le informazioni sono fornite in un formato elettronico di uso comune.
                </li>
                <li>
                Il diritto di ottenere una copia di cui al paragrafo 3 non deve ledere i diritti e le libertà altrui.
                </li>
              </ol>
            </div>

            {/* Section 13 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Come esercitare i diritti</h2>
              <p className="text-lg text-white leading-relaxed">
              Eventuali richieste di esercizio dei diritti dell'Utente possono essere indirizzate al Titolare attraverso i recapiti forniti in questo documento. La richiesta è gratuita e il Titolare risponderà nel più breve tempo possibile, in ogni caso entro un mese, fornendo all’Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno
              comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora egli lo richieda.
              </p>
            </div>

            {/* Section 14 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Trasferimenti di dati all’estero</h2>
              <p className="text-lg text-white leading-relaxed">
              Oxyera adotta misure tecniche e organizzative adeguate a proteggere i dati personali dell'interessato contro la perdita, l'uso improprio, l'accesso non autorizzato, la divulgazione, l'alterazione e la distruzione dei dati. Queste misure includono l'uso di crittografia, firewall e controlli di accesso sicuri. In base ai principi direzionali del Reg 679/2016 , le misure protettive vengono adottate dal titolare del trattamento dei dati tenendo conto della natura dei dati e dei rischi specificatamente individuati nel rispetto del principio generale di “accountability”.
              </p>
            </div>


            {/* Section 15 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Notifica di Violazioni dei Dati</h2>
              <p className="text-lg text-white leading-relaxed">
              In caso di scoperta violazione in materia di dati personali, Oxyera notificherà tempestivamente le autorità di controllo competenti entro 72 ore, o comunque senza ingiustificato ritardo ,fornendo informazioni dettagliate sulla natura della violazione e sulle misure adottate per mitigare i rischi.Si precisa che il presente obbligo di notifica si applica qualora il titolare del trattamento ritenga che dalla divulgazione del dato in questione possa derivare un rischio per i diritti e le libertà degli interessati.
              Qualora dalla valutazione discrezionale del titolare del trattamento dei dati personali sulla pericolosità della violazione , questa sia qualificabile come “ad alto rischio “, Oxyera si impegna a notificare anche direttamente al beneficiario del trattamento in base la normativa del Reg 679/2016.Il titolare del trattamento si impegna a tenere traccia in un apposito registro delle valutazioni discrezionali adottate in materia di “grado di pericolosità e rischi di divulgazione dei dati “.
              </p>
            </div>

            {/* Section 16 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Modifiche alla Presente Informativa</h2>
              <p className="text-lg text-white leading-relaxed">
              Oxyera si riserva il diritto di apportare modifiche alla presente informativa. Le modifiche saranno comunicate all'interessato tramite e-mail o notifica nell'App. <b>Fonte dei Dati Utilizzati e Pubblicati</b>
              La fonte dei dati utilizzati e pubblicati è Banche Dati Farmadati Italia®. Farmadati Italia garantisce il massimo impegno affinché la Banca dati e gli Aggiornamenti relativi ai farmaci, parafarmaci, dispositivi medici siano precisi, puntuali e costantemente aggiornati. Questo materiale è fornito solo a scopo didattico e non è inteso per consulenza medica, diagnosi o trattamento e non deve in nessun caso sostituirsi alla visita specialistica o ad un consulto medico. Farmadati Italia non si assume la responsabilità sull'utilizzo dei dati. E' doveroso contattare il proprio medico e/o uno specialista per la prescrizione e assunzione di farmaci, parafarmaci, dispositivi medici. L'ultimo aggiornamento dei dati e la messa online del database da parte di Oxyera sono stati effettuati il 17/07/2024.
              </p>
            </div>

            {/* Section 17 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Legge Applicabile e Foro Competente</h2>
              <p className="text-lg text-white leading-relaxed">
              La presente informativa è regolata dalla legge italiana.
              Per qualsiasi controversia derivante dall'interpretazione e/o esecuzione della presente informativa, le parti convengono la giurisdizione dell’ordinamento italiano, facendo salve le regole di determinazione del foro più favorevoli e inderogabili secondo la vigente disciplina europea in materia ex Reg CE 593/2008 e successive modifiche.
              </p>
            </div>

          </div>

          {/* Footer */}
          <p className="text-sm text-white mt-12 text-center">
          L’ultimo aggiornamento alla presente Informativa è stato effettuato il 17.07.2024.
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default TermOfCondition;
