import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import { IoInformationCircle } from 'react-icons/io5'; 
import oxyeraIcon from '../../../assets/icons/android-chrome-192x192.png';
import { useAuth } from '../../../context/AuthContext';
import debounce from 'lodash/debounce';
import { BiCartAdd, BiCheck } from "react-icons/bi";

const DetailPopUp = ({ onClose, aic }) => {
  const [product, setProduct] = useState({});
  const { setCart } = useAuth(); 
  const [addedItemId, setAddedItemId] = useState(null);  
  const modalRef = useRef(null);

  useEffect(() => {
    // Funzione asincrona per ottenere i dati
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://app.oxyera.com/medicine/aic/${aic}`);
        console.log('detailpopup = ',response.data); 
        setProduct(response.data);
      } catch (error) {
        console.error('Errore durante il fetch dei dati:', error);
      }
    };

    fetchData(); // Chiama la funzione di fetch

  }, [aic]); // Aggiungi 'aic' come dipendenza per ricaricare quando cambia

  // Funzione per chiudere il modal al click fuori dal contenitore
  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Debounced version of handleAddToCart
  const handleAddToCart = useCallback(debounce((product) => {
    setCart((prevCart) => {
      console.log('Previous Cart:', prevCart);
      
      const existingItemIndex = prevCart.findIndex(item => item.AIC === product.AIC);
      
      if (existingItemIndex !== -1) {
        // Item exists, increment quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += 1;
        console.log('Updated Cart with Incremented Quantity:', updatedCart);
        return updatedCart;
      } else {
        // Item does not exist, add with quantity 1
        const updatedCart = [...prevCart, { ...product, quantity: 1 }];
        console.log('Updated Cart with New Item:', updatedCart);
        return updatedCart;
      }
    });

    setAddedItemId(product.AIC);

    setTimeout(() => {
      setAddedItemId(null);
    }, 1000);

  }, 300), []);

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50 glassmorphism">
      <div ref={modalRef} className="bg-white rounded-lg shadow-lg w-auto p-6 flex flex-col items-center relative w-11/12">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <AiOutlineClose size={24} />
        </button>

        {product && (
          <>
            <img
              src={product.url || oxyeraIcon}
              alt="farmaco"
              className="w-full h-32 object-contain rounded mb-2"
            />
            <h3 className="text-lg font-semibold">
              {product.drug ? product.drug.split('*')[0] : 'Nome non disponibile'}
            </h3>
            <div className="flex flex-row justify-center items-center mt-2">
              <ul className="text-start mb-5">
                <li className="flex flex-row">
                  {product.drug && 
                  <>
                    <p className="me-2 font-medium">Confezione:</p> <span className="lowercase">{product.drug.split('*')[1]}</span>
                  </>
                  }
                </li>
                <li className="flex flex-row">
                  {product.active_principle &&
                  <>
                    <p className="me-2 font-medium">Principio attivo:</p> <span className="lowercase">{product.active_principle}</span>
                  </>
                  }
                </li>
                <li className="flex flex-row">
                  {product.categoryDescription && 
                  <>
                    <p className="me-2 font-medium">Categoria:</p> <span className="lowercase">{product.categoryDescription}</span>
                  </>
                  }
                </li>
                <li className="flex flex-row">
                  {product.company && 
                  <>
                    <p className="me-2 font-medium">Azienda:</p> <span className="lowercase">{product.company}</span>
                  </>
                  }
                </li>
                <li className="flex flex-row">
                  {product.public_price && 
                  <>
                    <p className="me-2 font-medium">Prezzo:</p> <span className="lowercase">{product.public_price.toFixed(2)}€</span>
                  </>
                  }
                </li>
                <li className="flex flex-row">
                    <span className="text-red-500 text-sm text-start">* Il prezzo indicato è quello consigliato al pubblico</span>
                </li>
              </ul>
            </div>
            <button 
              onClick={() => handleAddToCart(product)} 
              className="flex flex-row mt-auto w-full justify-center py-2 bg-blue-oxyera text-white rounded hover:opacity-75 transition-colors"
            >
              {addedItemId === product.AIC ? (
                <BiCheck className='text-2xl me-4 text-white' />
              ) : (
                <BiCartAdd className='text-2xl me-4 text-white' />
              )} 
              Aggiungi
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailPopUp;
