import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaCalendarAlt } from 'react-icons/fa';
import axios from 'axios';
import logoFarmacia from '../../../assets/logo/Untitled design (3).png';
import Recap from './Recap';
import { useAuth } from '../../../context/AuthContext';
import animationData from '../../../assets/lottie/Check.json';
import Lottie from 'react-lottie';
import CartComponent from "../component/CartComponent";


const OrderPopUp = ({ onClose }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint as needed
    const [selectedDate, setSelectedDate] = useState(getTomorrowDate());
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showRecap, setShowRecap] = useState(false);
    const { cart, setCart } = useAuth(); // Ensure setCart is available
    const [orderSubmitted, setOrderSubmitted] = useState(false); // New state to track order submission
    const [isCartOpen, setIsCartOpen] = useState(true);

    function getTomorrowDate() {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    }


    useEffect(() => {
        let isMounted = true; // Track component mount status

        async function fetchOptions() {
            try {
                const token = sessionStorage.getItem('access_token');
                if (!token) {
                    console.error('No access token found in session storage');
                    return;
                }

                const response = await axios.get('https://app.oxyera.com/pharmacy', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (isMounted) {
                    setOptions(response.data || []);
                }

            } catch (error) {
                console.error('Error fetching options:', error);
            }
        }

        fetchOptions();

        return () => {
            isMounted = false; // Clean up to prevent setting state if component unmounted
        };
    }, []); // Empty dependency array ensures this runs only once when component mounts

    const handleOptionClick = (optionId) => {
        setSelectedOption(optionId);
        setIsDropdownOpen(false);
    };

    const selectedOptionData = options.find(option => option.id === selectedOption);

    const handleContinue = () => {
        if(!selectedOptionData?.id){
            alert('Seleziona una farmacia!')
            return;
        }
        setShowRecap(true);
    };

    const handleConfirm = async () => {
        try {
            // Get the access token from session storage
            const token = sessionStorage.getItem('access_token');
            if (!token) {
                console.error('No access token found in session storage');
                return;
            }
            console.log(selectedDate, cart);
            
            // Prepare the POST request payload
            const requestBody = {
                pharmacy_id: selectedOptionData?.id, // Assuming `selectedOptionData` contains the pharmacy details
                type: 3, // Adjust the type as needed, this example assumes 1 for a standard order
                datetime: new Date(selectedDate).toISOString(), // Convert the selected date to ISO format
                medicines: cart.map(item => ({
                    medicine_aic: item.AIC, // Replace with the correct key from your order items
                    quantity: item.quantity,
                })),
            };
    
            // Make the POST request
            const response = await axios.post('https://app.oxyera.com/patient/order_request', requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            // Handle successful response
            console.log('Order confirmed:', response.data);

            if(response.status === 200){
                setOrderSubmitted(true);
                setCart([])
                return;
            }

        } catch (error) {
            // Handle errors
                setOrderSubmitted(false);
                console.error('Error confirming order:', error);
                return;
        }
    };

    const defaultOptions = {
        loop: false,  // Disable looping
        autoplay: true, // Start playing as soon as it's rendered
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    const goToNextStep = () => {
        if(cart.length > 0){
            setIsCartOpen(false);
        }else{
            alert('Devi aggiungere almeno un prodotto!');
        }
    }
    

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 glassmorphism">
            <div className="bg-white rounded-lg shadow-lg w-full sm:max-w-md max-w-sm p-6 flex flex-col items-center relative w-11/12">
                {/* Close Button */}  
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                    <AiOutlineClose size={24} />
                </button>

                {isCartOpen && isMobile ? 
                    <>
                        <CartComponent nextStep={goToNextStep}/>
                    </>
                    :
                    <>
                        {orderSubmitted ? (
                            <div className="my-4 text-center">
                                <h3 className="text-2xl font-semibold text-blue-oxyera mb-2">Ordine inviato!</h3>
                                <div className="my-4">
                                    <Lottie 
                                        options={defaultOptions}
                                        height={150}
                                        width={150}
                                    />
                                </div>
                                <button
                                    onClick={onClose}
                                    className="w-full py-2 bg-blue-oxyera text-white rounded"
                                >
                                    Chiudi
                                </button>
                            </div>
                        ) : ( !showRecap ? (
                            <>
                                <h2 className="text-2xl font-semibold mb-2">Seleziona data ritiro</h2>
                                <p className="text-gray-600 mb-6 text-center">Scegli il giorno in cui ritirare il tuo ordine in Farmacia</p>

                                {/* Date Input Field */}
                                <div className="relative w-2/3 mb-6">
                                    <FaCalendarAlt
                                        className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                                        onClick={() => document.getElementById('date').focus()}
                                    />
                                    <input
                                        type="date"
                                        id="date"
                                        value={selectedDate}
                                        min={getTomorrowDate()}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        className="block w-full text-md font-bold pl-10 pr-4 py-2 border border-2 border-blue-oxyera rounded-full shadow-sm focus:border-blue-500 focus:ring-0"
                                        style={{ 
                                            WebkitAppearance: 'none', 
                                            MozAppearance: 'none', 
                                            appearance: 'none' 
                                        }}
                                    />
                                </div>

                                <h2 className="text-2xl font-semibold mb-2">Seleziona Farmacia</h2>
                                <p className="text-gray-600 mb-6 text-center">Scegli in quale farmacia ritirare il tuo ordine</p>

                                {/* Custom Dropdown */}
                                <div className="relative w-full mb-4">
                                    <button
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                        className="block w-full border border-2 border-blue-oxyera rounded-full text-md font-semibold p-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    >
                                        <span className="flex items-center justify-between">
                                            <span className="flex items-center space-x-2">
                                                <img
                                                    src={selectedOptionData?.image || logoFarmacia}
                                                    alt="Selected"
                                                    className="w-8 h-8 rounded-full"
                                                />
                                                <span>{selectedOptionData?.display_name || 'Seleziona una farmacia'}</span>
                                            </span>
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </span>
                                    </button>
                                    {isDropdownOpen && (
                                        <ul className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-[10rem] overflow-y-auto no-scrollbar">
                                            {options.length > 0 ? (
                                                options.map(option => (
                                                    <li
                                                        key={option.id}
                                                        onClick={() => handleOptionClick(option.id)}
                                                        className="flex items-center p-2 cursor-pointer hover:bg-gray-200 text-start"
                                                    >
                                                        <img
                                                            src={logoFarmacia}
                                                            alt={option.display_name}
                                                            className="w-8 h-8 bg-contain mr-2"
                                                        />
                                                        <div>
                                                            <div className="font-semibold">{option.display_name}</div>
                                                            <div className="text-sm text-gray-600">{option.address}</div>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="p-2 text-gray-600">No options available</li>
                                            )}
                                        </ul>
                                    )}
                                </div>

                                {/* Continue Button */}
                                <button
                                    onClick={handleContinue}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none"
                                >
                                    Continua
                                </button>
                            </>
                        ) : (
                            <>
                            <Recap 
                                selectedDate={selectedDate}
                                selectedOptionData={selectedOptionData}
                                onClose={onClose}
                                onConfirm={handleConfirm}
                            />
                            </>
                        ))}
                    </>
                }
            </div>
        </div>
    );
};

export default OrderPopUp;
