import React from 'react';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import Logo from "../assets/logo/OXYERA_Logo_nbg.png";

function PrivacyPolicy() {
  return (
    <div>
      <Navbar />

      {/* Privacy Policy Page */}
      <section className="bg-blue-oxyera min-h-screen py-20">
        <div className="max-w-4xl mx-auto p-6 sm:p-10">
          {/* Header with Logo */}
          <div className="flex flex-col items-center justify-center mb-8">
            <img src={Logo} alt="Oxyera Logo" className="w-16 h-16 mr-4" />
            <h1 className="text-4xl font-semibold text-white">Privacy Policy di www.oxyera.com</h1>
          </div>

          {/* Introduction */}
          <p className="text-lg text-white mb-6 leading-relaxed">
          Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
          </p>

          {/* Sections */}
          <div className="space-y-8">
            {/* Section 1 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Titolare del Trattamento dei Dati</h2>
              <p className='text-lg text-white leading-relaxed'>Mario Alessandro Fiore – Project Consult Srl – dpo@leggesullaprivacy.it</p>
            </div>

            {/* Section 2 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Finalità del Trattamento dei Dati raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Statistica. Al fine di perseguire quest’ultima finalità il Titolare si avvale del servizio Google Analytics 4 ( Google Ireland Limited) il quale è è un servizio di statistica che utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google. Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. In Google Analytics 4, gli indirizzi IP vengono utilizzati al momento della raccolta e poi eliminati prima che i dati vengano registrati in qualsiasi data center o server. Per saperne di più, è possibile consultare la documentazione ufficiale di Google.<br/><br/>
              Per conoscere l'utilizzo dei Dati da parte di Google, consulta la loro partner policy e la loro pagina dei Dati Commerciali.<br/><br/> Dati Personali trattati: Dati di utilizzo; numero di Utenti; statistiche delle sessioni; Strumenti di Tracciamento.
              </p>
            </div>

            {/* Section 3 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Tipologie di Dati raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: Strumenti di Tracciamento; Dati di utilizzo; numero di Utenti; statistiche delle sessioni.<br/><br/>
              Dettagli completi su ciascuna tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei Dati stessi.
              I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.
              Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati
              come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.<br/><br/>
              L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy.
              </p>
            </div>

            {/* Section 4 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Base Giuridica del trattamento</h2>
              <p className="text-lg text-white leading-relaxed">
              Base giuridica del presente trattamento è il consenso (ex art. 6 lett.a GDPR 2016/679 - L’interessato ha espresso il consenso al trattamento dei propri dati personali per una o più specifiche finalità.").
              </p>
            </div>

            {/* Section 5 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Modalità del trattamento dei Dati raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
              Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche
              strettamente correlate alle finalità indicate.
              </p>
            </div>


            {/* Section 6 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Soggetti che possono avere accesso ai dati raccolti</h2>
              <p className="text-lg text-white leading-relaxed">
              Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se
              necessario, Responsabili del Trattamento da parte del Titolare. In tutti i casi, quest’ultimo ha impartito precise indicazioni, relative al trattamento dati di cui in oggetto, previo idonei accordi di riservatezza, a cui sono stati sottoposti tutti i propri dipendenti, e adeguati contratti di trattamento dati per i soggetti terzi a cui il Titolare si affida per la fornitura del servizio.
              </p>
            </div>

            {/* Section 7 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Trasferimenti di dati all’estero</h2>
              <p className="text-lg text-white leading-relaxed">
              Non è previsto alcun trasferimento di dati in paesi terzi sprovvisti di idonea decisione di adeguatezza né tantomeno ad organizzazioni internazionali.
              </p>
            </div>

            {/* Section 8 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Periodo di conservazione</h2>
              <p className="text-lg text-white leading-relaxed">
              I Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali a cui è soggetto il Titolare del trattamento..
              </p>
            </div>

            {/* Section 9 */}
            <div className='text-start'>
              <h2 className="text-2xl font-bold text-white mb-4">Diritti del soggetto del trattamento</h2>
              <ol className='list-decimal text-white'>
                <li>
                  <p className="text-lg text-white leading-relaxed">
                  L'interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:
                  </p>
                  <ul className='text-white list-disc ms-10'>
                    <li>
                    a) le finalità del trattamento;
                    </li>
                    <li>
                    b) le categorie di dati personali in questione;
                    </li>
                    <li>
                    c) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
                    </li>
                    <li>
                    d) quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;
                    </li>
                    <li>
                    e) l'esistenza del diritto dell'interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;
                    </li>
                    <li>
                    f) il diritto di proporre reclamo a un'autorità di controllo;
                    </li>
                    <li>
                    g) qualora i dati non siano raccolti presso l'interessato, tutte le informazioni disponibili sulla loro origine;
                    </li>
                    <li>
                    h) l'esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all'articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato.
                    </li>
                  </ul>
                </li>
                <li>
                Qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, l'interessato ha il diritto di essere informato dell'esistenza di garanzie adeguate ai sensi dell'articolo 46 relative al trasferimento.
                </li>
                <li>
                Il titolare del trattamento fornisce una copia dei dati personali oggetto di trattamento. In caso di ulteriori copie richieste dall'interessato, il titolare del trattamento può addebitare un contributo spese ragionevole basato sui costi amministrativi. Se l'interessato presenta la richiesta mediante mezzi elettronici, e salvo indicazione diversa dell'interessato, le informazioni sono fornite in un formato elettronico di uso comune.
                </li>
                <li>
                Il diritto di ottenere una copia di cui al paragrafo 3 non deve ledere i diritti e le libertà altrui.
                </li>
              </ol>
            </div>

            {/* Section 10 */}
            <div>
              <h2 className="text-xl font-bold text-white mb-4">Come esercitare i diritti</h2>
              <p className="text-lg text-white leading-relaxed">
              Eventuali richieste di esercizio dei diritti dell'Utente possono essere indirizzate al Titolare attraverso i recapiti forniti in questo documento. La richiesta è gratuita e il Titolare risponderà nel più breve tempo possibile, in ogni caso entro un mese, fornendo all’Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno
              comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora egli lo richieda.
              </p>
            </div>

            {/* Section 11 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Modifiche a questa privacy policy</h2>
              <p className="text-lg text-white leading-relaxed">
              Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di
              consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.<br/><br/>
              Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.
              </p>
            </div>

            {/* Section 12 */}
            <div>
              <h2 className="text-2xl font-bold text-white mb-4">Definizioni e riferimenti legali</h2>
              <p className='text-white font-semibold mb-5'>Dati Personali (o Dati)</p>
              <p className="text-lg text-white leading-relaxed">
              Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
              </p>

              <p className='text-white font-semibold my-5'>Dati di Utilizzo</p>
              <p className="text-lg text-white leading-relaxed">
              Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal
              visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.
              </p>

              
              <p className='text-white font-semibold my-5'>Utente</p>
              <p className="text-lg text-white leading-relaxed">
              L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.
              </p>

              <p className='text-white font-semibold my-5'>Interessato</p>
              <p className="text-lg text-white leading-relaxed">
              La persona fisica cui si riferiscono i Dati Personali.
              </p>

              <p className='text-white font-semibold my-5'>Responsabile del Trattamento (o Responsabile)</p>
              <p className="text-lg text-white leading-relaxed">
              La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
              </p>

              <p className='text-white font-semibold my-5'>Titolare del Trattamento (o Titolare)</p>
              <p className="text-lg text-white leading-relaxed">
              La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.
              </p>

              <p className='text-white font-semibold my-5'>Questa Applicazione</p>
              <p className="text-lg text-white leading-relaxed">
              Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
              </p>

              <p className='text-white font-semibold my-5'>Servizio</p>
              <p className="text-lg text-white leading-relaxed">
              Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.
              Unione Europea (o UE) <br/><br/>
              Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
              </p>

              <p className='text-white font-semibold my-5'>Cookie</p>
              <p className="text-lg text-white leading-relaxed">
              I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.
              </p>

              <p className='text-white font-semibold my-5'>Strumento di Tracciamento</p>
              <p className="text-lg text-white leading-relaxed">
              Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e- tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.
              </p>

              <p className='text-white font-semibold my-5'>Riferimenti legali</p>
              <p className="text-lg text-white leading-relaxed">
              Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione. <br/><br/>Ultima modifica: 9 agosto 2024
              </p>
            </div>

          </div>

          {/* Footer */}
          <p className="text-xl text-white mt-12 text-center">
          Cookie Policy
          </p>
          <p className="text-sm text-white mt-12 text-center">Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di più, gli Utenti possono consultare la Cookie Policy.</p>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
