import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'; // Import social media icons
import logo from '../assets/logo/OXYERA_Logo_nbg.png';


function Footer() {
  const lineRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the element is in viewport
    };
  
    const lineElement = lineRef.current; // Store the current element reference
    const elements = document.querySelectorAll('.animation-footer-text');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add animation class when element is in viewport
          lineElement.classList.add('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-0');
            element.classList.add('opacity-1');

          });

        } else {
          // Remove animation class when element is out of viewport
          lineElement.classList.remove('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-1');
            element.classList.add('opacity-0');
          });
        }
      });
    }, options);
  
    // Start observing the line element
    observer.observe(lineElement);
  
    // Clean up observer when component unmounts
    return () => {
      // Check if the lineElement is still valid before unobserving
      if (lineElement) {
        observer.unobserve(lineElement);
      }
    };
  }, []);



  return (
    <>
      <footer className="relative lg:w-full bg-white text-blue-oxyera lg:py-20 py-10 px-5">
        <div className={`flex items-center justify-start`}>
            <div className="flex justify-center items-center sm:w-56 me-0 sme-2 pb-5">
              <img src={logo} alt="Logo" className="h-10 w-auto" />
              <p className="ml-3 text-3xl font-semibold text-blue-oxyera">Oxyera</p>
            </div>
        </div>
        <div className="w-full flex justify-start lg:justify-center items-center z-20">
        <div className={`text-start lg:w-1/2`}>
            <p className={`lg:text-2xl text-lg font-semibold uppercase mb-2`}>Oxyera SB S.r.l</p>
            <p className={`text-sm lg:text-lg mb-2`}>Piazza Galeazzo Alessi, 2/7</p>
            <p className={`text-sm lg:text-lg mb-2`}>16128 Genova GE</p>
            <p className={`text-sm lg:text-lg mb-2`}>P.IVA: 02966660991</p>
            <p className={`text-sm lg:text-lg mb-2`}>info@oxyera.com</p>

            <hr ref={lineRef} className="border-b border-gray-300 my-4" />

            <div className="flex flex-wrap justify-start lg:space-x-8">
              <Link to="/privacy-policy" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{ "transition": "opacity 1.5s ease-in" }}>Privacy Policy</Link>
              <Link to="/terms-of-service" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{ "transition": "opacity 2s ease-in" }}>Termini e Condizioni</Link>
              <a href="https://www.linkedin.com/company/oxyera/" target="_blank" rel="noopener noreferrer" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{ "transition": "opacity 2.5s ease-in" }}><FaFacebook /></a>
              <a href="https://www.linkedin.com/company/oxyera/" target="_blank" rel="noopener noreferrer" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{ "transition": "opacity 3s ease-in" }}><FaInstagram /></a>
              <a href="https://www.linkedin.com/company/oxyera/" target="_blank" rel="noopener noreferrer" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{ "transition": "opacity 3.5s ease-in" }}><FaLinkedin /></a>
            </div>
          
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
