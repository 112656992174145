import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";

function PasswordRecovery({ onClose }) {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [status, setStatus] = useState(null);
    const [step, setStep] = useState(1); 
    const [authorization, setAuthorization] = useState('');


    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://app.oxyera.com/auth/forgot_password', { email: email });

            if (response.status === 200) {
                setStatus('otpSent');
                setAuthorization(response.data);
                setStep(2); 
                return;
            } 
            if(response.status === 404) {
                setStatus('error');
                return;
            }
        } catch (error) {
            setStatus('error');
            console.error("Error during password recovery:", error);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://app.oxyera.com/auth/verify_confirmation_code', 
                { 
                    confirmation_code: otp // Invia il codice OTP nel body
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${authorization}`, // Assicurati che l'autorizzazione sia corretta
                    },
                }
            );

            if (response.status === 200) {
                setAuthorization(response.data);
                setStatus('otpVerified');
                setStep(3); // Passa al passo 3 dopo la verifica OTP        
                return;
            } 
            if(response.status === 401) {
                setStatus('invalid');
                return;
            }
            if(response.status === 404) {
                setStatus('error');
                return;
            }
        } catch (error) {
            setStatus('error');
            console.error("Error during password recovery:", error);
        }

    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setStatus('passwordMismatch');
            return;
        }

        try {
            const response = await axios.post('https://app.oxyera.com/auth/reset_password', 
                { 
                    new_password: newPassword // Invia il codice OTP nel body
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${authorization}`, // Assicurati che l'autorizzazione sia corretta
                    },
                }
            );

            if (response.status === 200) {
                setStatus('success');
                setStep(4); 
                return;
            } 
            if(response.data === 401 || response.data === 404) {
                setStatus('error');
                return
            }
        } catch (error) {
            setStatus('error');
            console.error("Error during password reset:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 glassmorphism">
            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-sm">
                <div className="flex flex-row justify-end">
                    <button onClick={onClose}>
                        <IoClose className="text-2xl text-gray-600 hover:text-black transition-colors" />
                    </button>
                </div>
                <div className="flex flex-row justify-center items-center pb-5">
                    <img src={Logo} alt="Logo" className="h-10 w-auto" />
                    <p className="ml-2 text-2xl font-semibold text-blue-oxyera">Recupero Password</p>
                </div>

                {step === 1 && (
                    <form onSubmit={handleEmailSubmit} className="text-start">
                        <p className="text-gray-600 mt-2 mb-5">Inserisci la tua email per recuperare la password.</p>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mt-5"
                        >
                            Invia OTP
                        </button>
                        {status === 'error' && <p className="text-red-500 mt-2">Errore durante l'invio dell'email. Riprova.</p>}
                    </form>
                )}

                {step === 2 && (
                    <form onSubmit={handleOtpSubmit} className="text-start">
                        <p className="text-gray-600 mt-2 mb-5">Inserisci il codice OTP inviato alla tua email.</p>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Codice OTP</label>
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mt-5"
                        >
                            Verifica OTP
                        </button>
                        {status === 'invalid' && <p className="text-red-500 mt-2">Il codice inserito non è corretto!</p>}
                        {status === 'error' && <p className="text-red-500 mt-2">Errore durante la verifica OTP. Riprova.</p>}
                    </form>
                )}

                {step === 3 && (
                    <form onSubmit={handlePasswordReset} className="text-start">
                        <p className="text-gray-600 mt-2 mb-5">Inserisci la tua nuova password.</p>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Nuova Password</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Conferma Nuova Password</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mt-5"
                        >
                            Reimposta Password
                        </button>
                        {status === 'passwordMismatch' && <p className="text-red-500 mt-2">Le password non corrispondono. Riprova.</p>}
                        {status === 'error' && <p className="text-red-500 mt-2">Errore durante il reset della password. Riprova.</p>}
                    </form>
                )}

                {step === 4 && (
                    <div className="text-center">
                        <p className="text-green-500 mt-2">Password reimpostata con successo!</p>
                        <button
                            onClick={onClose}
                            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mt-5 mt-4"
                        >
                            Torna al Login
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PasswordRecovery;
