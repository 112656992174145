import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../assets/logo/OXYERA_Logo_nbg.png";
import { MdAccountCircle } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";
import LoginPopUp from "../components/login/PopUp"; // Adjust the path as necessary
import { useAuth } from '../context/AuthContext';


function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuTransition, setMenuTransition] = useState(false);
    const [hideLink, setHideLink] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false); // State for Login Pop-up
    const isActive = (path) => location.pathname === path;
    const { cart } = useAuth();
    const [token, setToken] = useState(sessionStorage.getItem('access_token'))
    const [isLogged, setIsLogged] = useState(false);


    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
        if(token){
            setIsLogged(true);
        }else{
            setIsLogged(false);
        }
    }, [isMenuOpen, token]);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
        setHideLink(false);
        setTimeout(() => {
            setMenuTransition(true);
        }, 500);
    };

    const handleMenuClose = () => {
        setMenuTransition(false);
        setIsMenuOpen(false);
    };

    const location = useLocation();

    useEffect(() => {
      // Handle scrolling to the section specified in the URL hash
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1)); // Remove the hash (#) character
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

    const handleLogout = () => {
        setIsMenuOpen(false)
        sessionStorage.removeItem('access_token');
        setIsLogged(false)
    };

    const handleLogin = () => {
        setIsMenuOpen(false)
        setIsLoginOpen(true)
    };


    const handleLoginStatus = (status) => {
        setIsLogged(status);
        // You can also perform other actions here, such as updating the UI or triggering further side effects
    };






    return (
    <>
        <div className={`fixed z-50 top-0 left-0 w-full transition-transform duration-300 translate-y-0 glassmorphism-white`}>
            <div className={`flex justify-between items-center p-5`}>
                <Link to="/">
                    <div className={`flex items-center justify-center`}>
                        <div className="flex justify-center items-center sm:w-56 me-0 sme-2">
                            <img src={Logo} alt="Logo" className="h-10 w-auto" />
                            <p className="ml-3 text-3xl font-semibold text-white">Oxyera</p>
                        </div>
                    </div>
                </Link>
                <div className="hidden xl:flex items-center">
                    <nav className="flex justify-end items-center text-white">
                        <Link to="/" className={`uppercase mx-6 ${isActive('/') ? 'underline-animate-active' : 'underline-animate'}`}>Home</Link>
                        <Link to="/prodotti" className={`uppercase mx-6 ${isActive('/prodotti') ? 'underline-animate-active' : 'underline-animate'}`}>Prodotti</Link>
                        <Link to="/servizi" className={`uppercase mx-6 ${isActive('/servizi') ? 'underline-animate-active' : 'underline-animate'}`}>Servizi</Link>
                        <Link to="/novita" className={`uppercase mx-6 ${isActive('/novita') ? 'underline-animate-active' : 'underline-animate'}`}>Novità</Link>
                        <Link to="/about-us" className={`uppercase mx-6 ${isActive('/about-us') ? 'underline-animate-active' : 'underline-animate'}`}>About Us</Link>
                        {/* <Link to="/contatti" className={`uppercase mx-6 ${isActive('/contatti') ? 'underline-animate-active' : 'underline-animate'}`}>Contatti</Link> */}
                        {isLogged ? 
                            <button 
                                onClick={handleLogout} 
                                className={`uppercase mx-6`}
                            >
                                Logout
                            </button>
                        : 
                            <button 
                                onClick={handleLogin} 
                                className={`uppercase mx-6`}
                            >
                                Login
                            </button>
                        }
                        <Link to="/prodotti" className="relative flex items-center mx-6">
                            <IoCartOutline className='text-3xl text-white' />
                            {cart.length > 0 && (
                                <span className="absolute -top-3 -right-4 bg-red-600 text-white text-[10px] rounded-full px-2 py-1">
                                    {cart.length}
                                </span>
                            )}
                        </Link>
                    </nav>
                </div>
                <div className="block xl:hidden">
                    <button className="w-6 h-6" onClick={handleMenuOpen}>
                        <div className=''><RxHamburgerMenu className='text-3xl text-white'/></div>
                    </button>
                    <nav className={`z-50 flex flex-col justify-top items-center absolute top-0 right-0 w-full h-full bg-blue-oxyera transition-all duration-[500ms] ease-in ${isMenuOpen ? 'opacity-100' : 'opacity-0'} ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'} ${isMenuOpen && 'h-screen'}`}>
                        {menuTransition && (
                            <>
                                <Link to="/" data-aos="fade-right" data-aos-duration="100" className={`text-white text-lg uppercase mx-6 mb-2 mt-36`}>Home</Link>
                                <Link to="/mobile/prodotti" data-aos="fade-right" data-aos-duration="200" className={`text-white text-lg uppercase mx-6 my-2`}>Prodotti</Link>
                                <Link to="/servizi" data-aos="fade-right" data-aos-duration="300" className={`text-white text-lg uppercase mx-6 my-2 old`}>Servizi</Link>
                                <Link to="/novita" data-aos="fade-right" data-aos-duration="400" className={`text-white text-lg uppercase mx-6 my-2 ld`}>Novità</Link>
                                <Link to="/about-us" data-aos="fade-right" data-aos-duration="500" className={`text-white text-lg uppercase mx-6 my-2 gold`}>About Us</Link>
                                {/* <Link to="/contatti" data-aos="fade-right" data-aos-duration="1250" className={`text-white text-lg uppercase mx-6 my-2 gold`}>Contatti</Link> */}
                                {isLogged ? 
                                    <button 
                                        onClick={handleLogout} 
                                        data-aos="fade-right" 
                                        data-aos-duration="600" 
                                        className={`text-white text-lg uppercase mx-6 my-2`}
                                    >
                                        Logout
                                    </button>
                                : 
                                    <button 
                                        onClick={handleLogin} 
                                        data-aos="fade-right" 
                                        data-aos-duration="600" 
                                        className={`text-white text-lg uppercase mx-6 my-2`}
                                    >
                                        Login
                                    </button>
                                }
                                <button className="absolute top-0 right-0 w-6 h-6 text-white m-10" onClick={handleMenuClose}>
                                    <IoClose className='text-3xl text-white'/>
                                </button>
                            </>
                        )}
                    </nav>
                </div>
            </div>
        </div>
        
        {/* Login Pop-up */}
        <LoginPopUp 
            isOpen={isLoginOpen} 
            onClose={() => setIsLoginOpen(false)} 
            onLoginStatus={handleLoginStatus} 
        />
    </>
    );
}

export default Navbar;
