import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useAuth } from '../../context/AuthContext';
import axios from "axios";
import Logo from "../../assets/logo/OXYERA_Logo_nbg.png";
import animationData from '../../assets/lottie/Check.json';
import animationAlert from '../../assets/lottie/alert.json';
import Lottie from 'react-lottie';
import PasswordRecovery from './RecoveryPWD';

function LoginPopUp({ isOpen, onClose, onSwitchToRegister, onLoginStatus }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const [loginStatus, setLoginStatus] = useState(0);
    const [name, setName] = useState('')
    const [showRecovery, setShowRecovery] = useState(false); 

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://app.oxyera.com/auth/signin', 
                {
                    email: email,
                    password_hash: password,
                }
            );

            if(response.status === 200) {
                sessionStorage.setItem('access_token', response.data.access_token);
                login(response.data);
                setLoginStatus(1);
                onLoginStatus(true)
                setName(response.data.firstname);
                console.log(response.status)
                return;
            }
            if(response.status === 401){
                setLoginStatus(2);
                console.log(response);
                return;   
            }
            if(response.status === 404){
                setLoginStatus(2);
                console.log(response);
                return;   
            }
            if(response.status === 406){
                setLoginStatus(2);
                console.log(response);
                return;   
            }

        } catch (error) {
            setLoginStatus(2);
            console.error("Error during login:", error);
            return;   
        }
    };

    const defaultOptions = {
        loop: false,  // Disable looping
        autoplay: true, // Start playing as soon as it's rendered
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const defaultOptionsError = {
        loop: false,  // Disable looping
        autoplay: true, // Start playing as soon as it's rendered
        animationData: animationAlert,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 glassmorphism">
            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-sm">
            {showRecovery ? (
                <PasswordRecovery onClose={() => setShowRecovery(false)} />  // Mostra il componente di recupero
            ) : (
                <>
                    {loginStatus === 0 &&
                        <>
                            <div className="flex flex-row justify-end">
                                <button onClick={onClose}>
                                    <IoClose className="text-2xl text-gray-600 hover:text-black transition-colors" />
                                </button>
                            </div>
                            <div className="flex flex-row justify-center items-center pb-5">
                                <img src={Logo} alt="Logo" className="h-10 w-auto" />
                                <p className="ml-2 text-2xl font-semibold text-blue-oxyera">Login</p>
                            </div>
                        </>
                    }
                    {loginStatus === 1 &&
                        <div className="my-4 text-center"> {/* Changed to text-center for centering */}
                            <h3 className="text-2xl font-semibold text-blue-oxyera mb-2">Bentornato {name}!</h3>
                            <div className="my-4">
                                <Lottie 
                                    options={defaultOptions}
                                    height={150}
                                    width={150}
                                />
                            </div>
                            <button
                                onClick={onClose}
                                className="w-full py-2 bg-blue-oxyera text-white rounded"
                            >
                                Chiudi
                            </button>
                        </div>
                    }
                    {loginStatus === 2 &&
                        <div className="mb-4 text-center"> {/* Changed to text-center for centering */}
                            <h3 className="text-lg font-semibold mb-2">Ops, qualcosa è andato storto!</h3>
                            <div className="my-4">
                                <Lottie 
                                    options={defaultOptionsError}
                                    height={150}
                                    width={150}
                                />
                            </div>
                            <button
                                onClick={onClose}
                                className="w-full py-2 bg-red-500 text-white rounded"
                            >
                                Riprova
                            </button>
                        </div>
                    }
                    {loginStatus === 0 &&
                        <>
                            <form onSubmit={handleSubmit} 
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  handleSubmit(event);  // Call the submit handler when 'Enter' is pressed
                                }
                              }} 
                             className="text-start">
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Email</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                                    />
                                </div>
                                <div className="mb-6">
                                    <div className="flex items-center">
                                        <label className="block text-sm font-medium text-gray-700">Password</label>
                                        <button className="text-blue-500 text-sm ms-3" onClick={() => setShowRecovery(true)}>Password dimenticata?</button>
                                    </div>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                                >
                                    Accedi
                                </button>
                            </form>
                            <div className="mt-4 text-center">
                                <p className="text-sm text-gray-600">
                                    Non hai un account?{" "}
                                    <button
                                        onClick={onSwitchToRegister}
                                        className="text-blue-500 hover:underline"
                                    >
                                        Registrati
                                    </button>
                                </p>
                            </div>
                        </>
                    }
                </>
            )}
            </div>
        </div>
    );
}

export default LoginPopUp;
