import React, { createContext, useState, useContext } from 'react';

// Create a context with a default value of null
const AuthContext = createContext(null);

// Create a provider component
export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(null);
    const [cart, setCart] = useState([]); // New state for cart items

    // Function to update the auth data
    const login = (data) => {
        setAuthData(data);
    };

    // Function to log out (clear the auth data)
    const logout = () => {
        setAuthData(null);
        setCart([]); // Clear the cart on logout
    };

    // Function to add a product to the cart
    const addToCart = (product) => {
        setCart((prevCart) => [...prevCart, product]);
    };

    return (
        <AuthContext.Provider value={{ authData, login, logout, cart, addToCart, setCart }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
